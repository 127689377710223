import React from 'react';
import { 
  Grid, useTheme,
} from '@mui/material';
import LogoCarpeta from '../../../../assets/images/logos/carpeta.png';
import LogoDocumento from '../../../../assets/images/logos/documento.png';
import LogoDocumentoAlerta from '../../../../assets/images/logos/documento-alerta.png'
import LogoDocumentoError from '../../../../assets/images/logos/documento-error.png';
import LogoFormulario from '../../../../assets/images/logos/formulario.png';
import LogoCarpetaVacia from '../../../../assets/images/logos/carpeta-vacia.png';
import LogoUsuario from '../../../../assets/images/logos/usuario.png';

export const Carpeta = ({ sx = {} }) => (
  <Grid container spacing={0} sx = {sx}>
    <img
      src={LogoCarpeta}
      alt="logo"
      style={{
        width: '100%'
      }}
    />
  </Grid>
);

export const Documento = ({ sx  = {}}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={0} sx = {sx}>
      <img
        src={LogoDocumento}
        alt="logo"
        style={{
          width: '100%',
          filter: `invert(${theme.palette.mode && theme.palette.mode === 'dark' ? '1' : '0'})`,
        }}
      />
    </Grid>
  );
}

export const DocumentoAlerta = ({ sx = {}}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={0} sx = {sx}>
      <img
      src={LogoDocumentoAlerta}
      alt="logo"
      style={{
        width: '100%',
        filter: `invert(${theme.palette.mode && theme.palette.mode === 'dark' ? '1' : '0'})`,
      }}
      />
    </Grid>
  );
}

export const DocumentoError = ({ sx = {}}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={0} sx = {sx}>
      <img
      src={LogoDocumentoError}
      alt="logo"
      style={{
        width: '100%',
        filter: `invert(${theme.palette.mode && theme.palette.mode === 'dark' ? '1' : '0'})`,
      }}
      />
    </Grid>
  );
}

export const Formulario = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={0} minHeight={20}>
      <img
        src={LogoFormulario}
        alt="logo"
        style={{
          width: '100%',
          maxWidth: '60px',
          minWidth: '30px',
          filter: `invert(${theme.palette.mode && theme.palette.mode === 'dark' ? '1' : '0'})`,
        }}
      />
    </Grid>
  );
}

export const CarpetaVacia = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={0} minHeight={20} sx = {{alignItems: 'center', justifyContent: 'center'}}>
      <img
        src={LogoCarpetaVacia}
        alt="logo"
        style={{
          width: '100%',
          maxWidth: '90px',
          minWidth: '30px',
          filter: `invert(${theme.palette.mode && theme.palette.mode === 'dark' ? '1' : '0'})`,
        }}
      />
    </Grid>
  );
}

export const Usuario = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={0} minHeight={20}>
      <img
        src={LogoUsuario}
        alt="logo"
        style={{
          width: '100%',
          maxWidth: '90px',
          minWidth: '30px',
          filter: `invert(${theme.palette.mode && theme.palette.mode === 'dark' ? '1' : '0'})`,
        }}
      />
    </Grid>
  );
}