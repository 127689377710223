import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import { useSnackbar } from 'notistack';
import { Grid } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import { choosePlan } from '../../api/subscription';
import { PUBLIC_KEY_MERCADO_PAGO_AR, PUBLIC_KEY_MERCADO_PAGO_CL } from '../../constants/keys';

const LocalCardPayment = ({
  plan,
  setSubscription,
  setStep = null,
  formik,
  total,
  totalDesc,
  codigoDescuento,
}) => {
  initMercadoPago(
    plan && plan.pais.nombre === 'Argentina'
      ? PUBLIC_KEY_MERCADO_PAGO_AR
      : PUBLIC_KEY_MERCADO_PAGO_CL,
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  /* const auth = useAuth(); */
  const selectedLanguage = localStorage.getItem('language');

  const { authenticatedData } = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const monto = totalDesc !== null ? totalDesc : searchParams.get('monto');
  const showAlert = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
    });
  };

  useEffect(() => {
    // initMercadoPago('APP_USR-0ab2f4fc-2522-4980-a541-d36c62a96815');
    //  if(plan && plan.pais.nombre === 'Argentina') {
    //   initMercadoPago('APP_USR-15df327f-43d0-4ce8-bb8c-d0a86a69b570');
    //  } else if(plan && plan.pais.nombre === 'Chile') {
    //     initMercadoPago('APP_USR-45ee4d56-86d0-418c-a835-eb4a06586264');
    //  }
    return () => {
      window.cardPaymentBrickController.unmount();
    };
  }, []);

  const initialization = {
    amount: total ?? (monto || plan.monto),
    three_d_secure_mode: 'optional',
  };

  const onSubmit = async (formData) => {
    const deviceId = document.getElementById('deviceId');
    try {
      const suscripcion = {
        empresa: { id: authenticatedData?.idEmpresa },
        emailCliente: formData.payer.email,
        cardTokenId: formData.token,
        monto: formData.transaction_amount,
        idPromocion: plan.id,
        codigoDescuento,
        deviceId: deviceId.value,
      };

      if (formik) {
        formik.setFieldValue('suscripcion', suscripcion);
        formik.submitForm();
      } else {
        await choosePlan(suscripcion);
        showAlert(`Suscripción exitosa`, 'success');
        if (typeof setSubscription === 'function') {
          setSubscription('success');
        } else {
          console.warn('setSubscription no es una función válida');
        }
        if (typeof setStep === 'function') {
          setStep(3);
        } else {
          console.warn('setStep');
        }        
        navigate('/configuracion/exito');
        return;
      }
    } catch (err) {
      console.log(err);
      showAlert(err?.response?.data?.error || `Error al realizar la suscripción`, 'error');
      navigate('/configuracion/rechazado');
    }
  };

  const onError = async (error) => {
    // callback llamado para todos los casos de error de Brick
    console.log(error);
  };
  const onReady = async () => {
    /*
   Callback llamado cuando Brick está listo.
   Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
 */
  };

  const customization = {
    visual: {
      style: {
        customVariables: {
          buttonTextColor: 'rgba(0, 0, 0, 0.87)', // Cambia el color del texto del botón aquí
          baseColor: '#ffbf3c', // Cambia el color de base aquí
          // baseColorFirstVariant: '#FF0000', // Cambia el primer color de variante de base aquí
          // baseColorSecondVariant: '#FF0000', // Cambia el segundo color de variante de base aquí
        },
      },
    },
    paymentMethods: {
      maxInstallments: 1,
    },
  };

  return (
    <Grid sx={{ border: '3px solid #ccc', borderRadius: '0.5rem' }}>
      <CardPayment
        initialization={initialization}
        onSubmit={onSubmit}
        onReady={onReady}
        onError={onError}
        locale={selectedLanguage}
        customization={customization}
      />
    </Grid>
  );
};

export default LocalCardPayment;
