import axios from "axios";
import { renewToken } from "../api/auth";
import { AUTH_PATH } from ".";

export const reqConf = config => {
    const newConfig = {...config}
    const token = localStorage.getItem('accessToken');
    if (token && token !== 'null') {
        newConfig.headers.Authorization = token
      }
      return newConfig
}
export const reqErr = error => {
    Promise.reject(error)
  }

export const resFilter = response => {
    return response
  }

export const resErr = async error => {
    const originalRequest = error.config
    if (
        error.response.status === 401 &&
        originalRequest.url === `${AUTH_PATH}/renovar-token` ||
        error?.response?.data?.error === "TokenExpiredException" &&
        error?.response?.data?.retry === false
      ) {
        window.location.replace('/auth/login')
        localStorage.removeItem('accessToken')
        return Promise.reject(error)
      }
    if(error?.response?.data?.error === "TokenExpiredException" && error?.response?.data?.retry !== false) {
        const accessToken = localStorage.getItem('accessToken')
        if(accessToken !== null) {
          const res = await renewToken(localStorage.getItem('accessToken'))
          if (res.token) {
              localStorage.setItem('accessToken',res.token)
              axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
              return axios(originalRequest)
          }
          if(res.status === 401 && error?.response?.data?.error === "TokenExpiredException" && error?.response?.data?.retry !== false) {
            window.location.replace('/auth/login')
              return Promise.reject(error)
          }
        }
    }
    return Promise.reject(error)
}
