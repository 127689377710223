import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getCargasPaginado, getPedidosPublicados } from '../../../../api/marketplace';

export const useCargas = (selectedFilters, url) => {
  const [page, setPage] = useState(0);
  const [dashboardCargas, setDashboardCargas] = useState({ content: [] });
  const [cargas, setCargas] = useState([]);
  const [detalleAbierto, setDetalleAbierto] = useState(false);
  const [detalleCarga, setDetalleCarga] = useState(null);
  const [maxKmTotales, setMaxKmTotales]= useState()
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const loadCargas = async () => {
    setLoading(true);
    try {
      let response; 
      if (!url) {
        response = await getCargasPaginado(page,25);
      } else {
        response = await getPedidosPublicados(0, 25,url);
      }
      setDashboardCargas(response.data);
      setTotal(response.totalElements);
      setMaxKmTotales(Math.max(...response.data.content.map(objeto => objeto.kmTotales === 'NaN' ? 0 : parseFloat(objeto.kmTotales))));
    } catch (error) {
      setDashboardCargas({ content: [] });
    } finally {
      setLoading(false);
    }
  };

  const reloadCargas = () => {
    loadCargas();
  };

  const filtrarUbicacion = (ubicacionDondeBuscar, ubicacionABuscar) => {
    if (!ubicacionDondeBuscar) return true;
    const {
      pais: paisABuscar,
      provincia: provinciaABuscar,
      localidad: localidadABuscar,
    } = ubicacionABuscar;
    const { pais, provincia, localidad, direccion } = ubicacionDondeBuscar;
    let result = true;
    if (paisABuscar?.id)
      result =
        pais?.id === paisABuscar.id ||
        direccion?.toUpperCase().includes(paisABuscar.nombre.toUpperCase());
    if (provinciaABuscar?.id)
      result =
        provincia === provinciaABuscar.nombre ||
        direccion?.toUpperCase().includes(provinciaABuscar.nombre.toUpperCase());
    if (localidadABuscar?.id)
      result =
        localidad === localidadABuscar.nombre ||
        direccion?.toUpperCase().includes(localidadABuscar.nombre.toUpperCase());
    return result;
  };

  const filtrar = (cargaArray) => {
    return cargaArray.filter((item) => {
      let p = true;

      if (selectedFilters.ubicacionDeCarga) {
        const desde = filtrarUbicacion(item.ubicacionDesde, selectedFilters.ubicacionDeCarga);
        const hasta = filtrarUbicacion(item.ubicacionHasta, selectedFilters.ubicacionDeCarga);
        if (!desde && !hasta) return false;
      }

      if (selectedFilters?.tipoCarga) {
        p = item?.pedidos.some((carga) => carga.tipoCarga.id === selectedFilters.tipoCarga);
        if (p === false) return p;
      }

      if (selectedFilters?.favorito) {
        p = item.idEmpresaCreadora === selectedFilters.favorito;
        if (p === false) return p;
      }

      if (selectedFilters?.tipoDeSemirremolque) {
        p = item?.tipoRemolque?.id === selectedFilters.tipoDeSemirremolque;

        if (p === false) return p;
      }

      if (selectedFilters?.embalaje) {
        p = item.pedidos.some((unaCarga) =>
          unaCarga.itemCarga
            ?.map((elem) => elem.tipoEmbalaje?.id)
            .some((idEmbalaje) => idEmbalaje === selectedFilters.embalaje),
        );
        if (p === false) return p;
      }

      if (selectedFilters?.tipoDeOferta) {
        if (selectedFilters.tipoDeOferta === 2) return item?.recibirOfertas === true;
        if (selectedFilters.tipoDeOferta === 1) return item?.recibirOfertas === false;
      }

      if (selectedFilters?.distancia) {
        const [desde, hasta] = selectedFilters.distancia;
        p = desde <= parseInt(item?.kmTotales, 10) && parseInt(item?.kmTotales, 10) <= hasta;

        if (p === false) return p;
      }

      return p;
    });
  };

  const ordenarPorPrioridad = () => {
    return cargas.sort((a, b) => {
      return a.destacado < b.destacado ? 1 : a.destacado > b.destacado ? -1 : 0;
    });
  };
  const ordenarPorFechaYHora = (primerFecha, primerHorario, segundaFecha, segundoHorario) => {
    const fechaUno = new Date(`${primerFecha}T${primerHorario}`).getTime();
    const fechaDos = new Date(`${segundaFecha}T${segundoHorario}`).getTime();
    return fechaUno < fechaDos ? 1 : fechaUno > fechaDos ? -1 : 0;
  };
  const ordenarPorFechaDeCarga = () => {
    return cargas.sort((a, b) => {
      const periodoDeCargaA = a.periodoDeCarga;
      const periodoDeCargaB = b.periodoDeCarga;
      return ordenarPorFechaYHora(
        periodoDeCargaA.finalizacion,
        periodoDeCargaA.horaFinalizacion,
        periodoDeCargaB.finalizacion,
        periodoDeCargaB.horaFinalizacion,
      );
    });
  };

  const ordenarPorFechaDePublicacion = () => {
    return cargas.sort((a, b) => {
      const fechaUno = new Date(a.fechaPublicado);
      const fechaDos = new Date(b.fechaPublicado);
      return fechaUno.getTime() < fechaDos.getTime()
        ? 1
        : fechaUno.getTime() > fechaDos.getTime()
        ? -1
        : 0;
    });
  };

  const ordenarPorFechaDeEntrega = () => {
    return cargas.sort((a, b) => {
      const periodoDeDescargaA = a.periodoDeDescarga;
      const periodoDeDescargaB = b.periodoDeDescarga;
      return ordenarPorFechaYHora(
        periodoDeDescargaA.finalizacion,
        periodoDeDescargaA.horaFinalizacion,
        periodoDeDescargaB.finalizacion,
        periodoDeDescargaB.horaFinalizacion,
      );
    });
  };

  const ordenar = (tipoDeOrdenamiento) => {
    switch (tipoDeOrdenamiento) {
      case 1:
        return ordenarPorFechaDeCarga();
      case 2:
        return ordenarPorFechaDeEntrega();
      case 3:
        return ordenarPorFechaDePublicacion();
      default:
        ordenarPorPrioridad();
    }
    return cargas;
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const removeCarga = (id) => {
    setCargas(cargas.filter((carga) => carga.id !== id));
  };

  const editCarga = () => {
  };

  const handleDetalle = (cargaDetalle) => {
    setDetalleCarga(cargaDetalle);
    setDetalleAbierto(!detalleAbierto);
    if (!detalleAbierto) {
      navigate(`/marketplace/inicio/${cargaDetalle.id}`);
    } else {
      navigate(`/marketplace/inicio`);
      // reloadCargas();
    }
  };

  useEffect(() => {
    loadCargas();
  }, []);

  useEffect(() => {
    if (dashboardCargas.content) {
      setCargas(dashboardCargas.content);
      setCargas(filtrar(dashboardCargas.content));
      setPage(1);
    }
  }, [dashboardCargas.content]);

  useEffect(() => {
    if (dashboardCargas.content) {
      setCargas(filtrar(dashboardCargas.content));
      setPage(1);
    }
  }, [selectedFilters]);

  return {
    cargas,
    detalleAbierto,
    detalleCarga,
    editCarga,
    handleChangePage,
    handleDetalle,
    loading,
    reloadCargas,
    ordenar,
    page,
    removeCarga,
    setCargas,
    maxKmTotales
  };
};
