import axios from 'axios';
import { CODE_PATH } from '../config';

export const getDiscountCode = async (code) => {
  return axios.get(`${CODE_PATH}/ver?codigo=${code}`);
};

export const verifyDiscountCode = async (code, idPromocion) => {
  return axios.get(`${CODE_PATH}/verificar?codigo=${code}&idPromocion=${idPromocion}`);
};

export const getAllDiscountCoidesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${CODE_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getDiscountCodesListPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${CODE_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deleteDiscountCode = async (id) => {
  return axios.delete(`${CODE_PATH}/eliminar?id=${id}`);
};

export const createDiscountCode = async (discountCode) => {
  return axios.post(`${CODE_PATH}/guardar`, discountCode);
};
