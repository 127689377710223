import React, { useContext } from 'react';
import { Box, Card, Divider, CardHeader, CardContent, Typography, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { parse, format } from 'date-fns';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

const GastosChart = ({ data, title, strToolstip, dataGastosMonth, setDataGastosMonth }) => {
  const [t] = useTranslation();
  const {padMarByScreen, heightCardHeader, heightScreen, sizeFontCardHeader, widthScreen, mMainCard}= useContext(ScreenSizeContext);

  const barWidth = Math.min(40, 200 / data.length);

  const subheader = () => {
    if (dataGastosMonth && dataGastosMonth.length>0) {
      const fechaOriginal = dataGastosMonth[0]?.fecha;
      const fechaObjeto = parse(fechaOriginal, 'MMM dd, yyyy', new Date());

      const fechaFormateada = format(fechaObjeto, 'MMM yyyy');
      return fechaFormateada;
    }
    return null;
  };

  return (
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb: 1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen,height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={title}
        subheader={subheader()}
        action={
          dataGastosMonth && (
            <IconButton
              aria-label="recargar"
              onClick={() => setDataGastosMonth(null)}
              color="primary"
              title={t('All the expenses')}
            >
              <RefreshIcon />
            </IconButton>
          )
        }
      />
      <Divider sx={{ backgroundColor: 'orange', marginBottom: heightScreen>900? 3:0, mx:2}}/>

      <CardContent>
        {(data || dataGastosMonth) && (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              width="100%"
              height={500}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              data={!dataGastosMonth ? data : dataGastosMonth}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis
                // axisLine={false}
                tickLine={false}
                domain={[0, 4]}
                tickFormatter={(str) => {
                  if (str >= 1000000000000)
                    return Number.isInteger(str / 1000000000000)
                      ? `${(str / 1000000000000).toString()}B`
                      : `${(str / 1000000000000).toFixed(1)}B`;

                  if (str >= 1000000)
                    return Number.isInteger(str / 1000000)
                      ? `${(str / 1000000).toString()}M`
                      : `${(str / 1000000).toFixed(1)}M`;

                  if (str >= 1000)
                    return Number.isInteger(str / 1000)
                      ? `${(str / 1000).toString()}K`
                      : `${(str / 1000).toFixed(1)}K`;

                  return Number.isInteger(str) ? str.toString() : str.toFixed(1);
                }}
                label={{ value: t('Bills'), angle: -90, position: 'insideBottomLeft', offset: 10 }}
              />
              <XAxis
                dataKey="fecha"
                //  axisLine={false}
                tickSize={5}
                tickFormatter={(str) => {
                  return `${t(str)}`;
                }}
                label={{ value: t('Fechas'), position: 'insideBottomRight', offset: -10 }}
              />

              <Tooltip
                content={(props) => <CustomTooltip strToolstip={strToolstip} {...props} />}
              />
              <Legend verticalAlign='bottom' height={widthScreen>1250 || (widthScreen>700 && widthScreen<900 )? 1: '11%'} align='right' wrapperStyle={{ paddingTop: '0.8rem' }} />

              <Bar
                dataKey="COMIDA"
                fill="#FFA500"
                activeBar={<Rectangle fill="#FFA500" stroke="#FFA500" />}
                name={t('Comida')}
                barSize={barWidth}
              />
              <Bar
                dataKey="REPARACION"
                fill="#FF4500"
                activeBar={<Rectangle fill="#FF4500" stroke="#FF4500" />}
                name={t('Reparacion')}
                barSize={barWidth}
                barGap={0}
              />
              <Bar
                dataKey="COMBUSTIBLE"
                fill="#006400"
                activeBar={<Rectangle fill="pink" stroke="#006400" />}
                name={t('Combustible')}
                barSize={barWidth}
              />
              <Bar
                dataKey="PEAJE"
                fill="#808080"
                activeBar={<Rectangle fill="pink" stroke="#808080" />}
                name={t('Peaje')}
                barSize={barWidth}
              />
              <Bar
                dataKey="ALOJAMIENTO"
                fill="#001F3F"
                activeBar={<Rectangle fill="pink" stroke="#001F3F" />}
                name={t('Alojamiento')}
                barSize={barWidth}
              />
              <Bar
                dataKey="OTRO"
                fill="#303030"
                activeBar={<Rectangle fill="pink" stroke="#303030" />}
                name={t('Otro')}
                barSize={barWidth}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default GastosChart;

const CustomTooltip = ({ active, payload, label }) => {
  const [t] = useTranslation();
  if (active && payload && payload.length) {
    return (
      <Card>
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={`${t(label)}`}
        />
        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ paddingLeft: 1, paddingTop: 0, margin: 0, marginBottom: -2 }}>
          {payload?.map((el) => (
            <Box
              sx={{
                backgroundColor: 'white',
                marginTop: 1,
                borderLeft: 6,
                borderLeftColor: el.color,
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                paddingLeft: 0.5,
              }}
            >
              <Typography> {`${el.name}: $${el.value.toFixed(2)}`}</Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }
  return null;
};
