import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  alpha,
  Autocomplete,
  Avatar,
  Grid,
  IconButton,
  TextField,
  Typography,
  Chip,
  InputAdornment,
  Box,
} from '@mui/material';
import moment from 'moment';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CustomFormLabel from '../components/forms/custom-elements/CustomFormLabel';
import CustomTextField from '../components/forms/custom-elements/CustomTextField';
import { chooseVehicle, fetchGetPalletTypes } from '../redux/customizer/Action';

export const getTextField = (property, formik, props = {}, itemId) => {
  const conditionToHideAlternativePayment =
    (itemId === 'publicarMarketplace' &&
      property.id === 'publicarMarketplace_otro' &&
      formik.values?.publicarMarketplace_condicionPago?.nombre !== 'Otro') ||
    (itemId === 'publicarFavoritos' &&
      property.id === 'publicarFavoritos_otro' &&
      formik.values?.publicarFavoritos_condicionPago?.nombre !== 'Otro') ||
    (itemId === 'asignarFavorito' &&
      property.id === 'asignarFavorito_otro' &&
      formik.values?.asignarFavorito_condicionPago?.nombre !== 'Otro');

  if (conditionToHideAlternativePayment) return '';

  const handleKeyPress = (event) => {
    const key = event.key.toLowerCase();
    if (key === '-' || key === 'e') {
      event.preventDefault();
    }
  };

  const getInputProps = (properti) => {
    if (properti?.type === 'password') {
      return {
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
      };
    }
    if (property.type === 'number') {
      return {
        min: 0,
      };
    }
    return {};
  };

  const valueProperty = property.multiple
    ? formik.values?.[property.array]?.[property.key]?.[property.field]
    : formik.values[property.id];

  const { endProp } = props;
  const propsWithoutEndProp = { ...props };
  delete propsWithoutEndProp.endProp;

  const handleOnChange = (e) => {
    if (property.id === 'direccion' && formik.values.direccionInfo) {
      formik.setFieldValue('direccionInfo', null);
    }
    const partes = property.id.split('.');
    if (partes[1] === 'peso') {
      if (/^0/.test(e.target.value)) return;
    }
    formik.setFieldValue(property.id, e.target.value);
  };

  return (
    <Grid
      item
      key={property.id}
      xs={property.xs}
      md={property.md || property.xs}
      lg={property.lg || property.xs}
    >
      {!property.hideLabel && (
        <CustomFormLabel htmlFor={property.id}>
          {property.label} {property.required ? <strong>*</strong> : <></>}
        </CustomFormLabel>
      )}
      <CustomTextField
        id={property.id}
        name={property.id}
        type={property.type || null}
        onBlur={formik.handleBlur}
        onChange={handleOnChange}
        value={valueProperty || ''}
        disabled={property.disabled}
        label={(property.showLabel && property.label) || ''}
        multiline={Boolean(property.multiline)}
        rows={property.multiline || 1}
        maxLength={props.maxLength}
        error={Boolean(
          !property.multiple
            ? formik.touched[property.id] && formik.errors[property.id]
            : formik.touched[property.array]?.[property.key]?.[property.field] &&
                formik.errors[property.array]?.[property.key]?.[property.field],
        )}
        helperText={
          !property.multiple
            ? formik.touched[property.id] && formik.errors[property.id]
            : formik.touched[property.array]?.[property.key]?.[property.field] &&
              formik.errors[property.array]?.[property.key]?.[property.field]
        }
        variant="outlined"
        fullWidth
        autoComplete="off"
        InputProps={{
          endAdornment: endProp ? <InputAdornment position="end">{endProp}</InputAdornment> : null,
          inputProps: getInputProps(property),
        }}
        {...propsWithoutEndProp}
        placeholder={property.placeholder && property.placeholder}
        onKeyPress={(e) => property?.type === 'number' && handleKeyPress(e)}
      />
      <Typography
        style={{
          color: '#C93831',
          fontSize: 12,
          paddingLeft: 14,
        }}
      >
        {formik.errors.ubicaciones?.[`${property.field}`] ||
          formik.touched.ubicaciones?.[`${property.field}`]}
      </Typography>
    </Grid>
  );
};

export const GetAutocomplete = (
  property,
  formik,
  listItems,
  props,
  setPointA,
  setPointB,
  mapLoaded,
  assignCountryProvinceLocality,
) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const filterOptions = (options, { inputValue }) => {
    return options?.filter((option) => {
      return (
        option?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
        option?.sku?.toLowerCase().includes(inputValue?.toLowerCase())
      );
    });
  };

  const hasPrecioProducto = listItems?.some((item) => item.precioProducto !== undefined);

  const listPallets = useSelector((state) => state.CustomizerReducer.typePallets);

  const handleChange = (event, value) => {
    formik.setFieldValue(property.id, value);

    if (property.field === 'selectProduct') {
      const cadena = property.id;
      const partes = cadena.split('.');
      const primeraParte = partes[0];

      if (value === null) {
        formik.setFieldValue(`${primeraParte}.sku`, '');
        formik.setFieldValue(`${primeraParte}.volumen`, '');
        formik.setFieldValue(`${primeraParte}.cantidadPallets`, ''); 
        formik.setFieldValue(`${primeraParte}.cantidad`, ''); 
        formik.setFieldValue(`${primeraParte}.ncm`, null);
        formik.setFieldValue(`${primeraParte}.tipoPallet`, null);
        formik.setFieldValue(`${primeraParte}.valorMercaderia`, '');
        formik.setFieldValue(`${primeraParte}.monedaId`, undefined);
        return;
      }

      formik.setFieldValue(`${primeraParte}.sku`, value.sku);
      formik.setFieldValue(`${primeraParte}.volumen`, value.volumen);

      formik.setFieldValue(`${primeraParte}.cantidadPallets`, 1);

      formik.setFieldValue(`${primeraParte}.cantidad`, value.cantidad);

      const filterPallet = listPallets.find((pallet) => pallet.id === value.idTipoPallet);
      formik.setFieldValue(`${primeraParte}.tipoPallet`, filterPallet);
      formik.setFieldValue(`${primeraParte}.valorMercaderia`, value.precioProducto.monto);
      formik.setFieldValue(`${primeraParte}.monedaId`, value.precioProducto.moneda);
      formik.setFieldValue(`${primeraParte}.ncm`, {
        value: value.ncm.id,
        label: `${value.ncm.codigo} ${value.ncm.descripcion}`,
      });
    }

    if (property.id === 'tipoVehiculo') dispatch(chooseVehicle(value.nombre));

    if (property.id === 'seleccionarOrigen') {
      if (value) {
        const locationResult = {
          lat: value.latitud || 0,
          lng: value.longitud || 0,
        };
        setPointA(locationResult);
        formik.setFieldValue('latitudOrigen', value.latitud);
        formik.setFieldValue('longitudOrigen', value.longitud);
        formik.setFieldValue('origen', value.direccion);
        mapLoaded?.panTo(locationResult);
        assignCountryProvinceLocality(property.id, value);
      }
    }

    if (property.id === 'seleccionardestino') {
      const locationResult = {
        lat: value.latitud || 0,
        lng: value.longitud || 0,
      };
      setPointB(locationResult);
      formik.setFieldValue('latitudDestino', value.latitud);
      formik.setFieldValue('longitudDestino', value.longitud);
      formik.setFieldValue('destino', value.direccion);
      mapLoaded?.panTo(locationResult);
      assignCountryProvinceLocality(property.id, value);
    }
  };

  const remolqe = 'remolque';
  let valueProperty = '';
  if (
    property.id === 'vehiculo' &&
    (formik.values[property.id] === null || formik.values[property.id] === '')
  ) {
    valueProperty =
      property.multiple && property.array
        ? formik.values[property.array][property.key][property.field]
        : formik.values[remolqe];
  } else {
    valueProperty =
      property.multiple && property.array
        ? formik.values[property.array][property.key][property.field]
        : formik.values[property.id];
  }

  useEffect(() => {
    if (property.field === 'selectProduct') {
      dispatch(fetchGetPalletTypes());
    }
  }, [dispatch]);

  return (
    <Grid
      item
      key={property.id}
      xs={property.xs}
      md={property.md || property.xs}
      lg={property.lg || property.xs}
    >
      {!property?.hideLabel && (
        <CustomFormLabel htmlFor={property.id}>
          {property.label} {property.required ? '*' : ''}
        </CustomFormLabel>
      )}
      <Autocomplete
        disablePortal
        id={property.id}
        options={listItems || []}
        multiple={(property?.multiple && !property.array) || false}
        value={valueProperty || null}
        disabled={property.disabled}
        fullWidth
        onBlur={formik.handleBlur}
        onChange={handleChange}
        filterOptions={hasPrecioProducto ? filterOptions : undefined}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={Boolean(
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
            }
            size="medium"
            aria-label={property.label}
            label={(property.showLabel && property.label) || ''}
            placeholder={props.placeholder || ''}
          />
        )}
        {...props}
      />
      {property.id === 'chofer' && valueProperty !== null ? (
        <Box sx={{ float: 'right' }}>
          {t('Fleet.My-Travels.Edit.State')} &nbsp;
          <Chip
            sx={{
              backgroundColor:
                valueProperty.estado.toUpperCase() === 'DISPONIBLE'
                  ? (theme) => theme.palette.success.main
                  : valueProperty.estado.toUpperCase() === 'NO DISPONIBLE' ||
                    valueProperty.estado.toUpperCase() === 'NO_DISPONIBLE'
                  ? (theme) => theme.palette.secondary.main
                  : valueProperty.estado.toUpperCase() === 'EN_VIAJE' ||
                    valueProperty.estado.toUpperCase() === 'EN VIAJE'
                  ? (theme) => theme.palette.warning.main
                  : valueProperty.estado.toUpperCase() === 'LICENCIA'
                  ? (theme) => theme.palette.error.main
                  : (theme) => theme.palette.secondary.main,
              color: '#fff',
              borderRadius: '6px',
            }}
            size="small"
            label={t(`Status.${valueProperty.estado}`)}
          />
        </Box>
      ) : (
        ''
      )}
      {property.id === 'vehicle' && valueProperty !== null ? (
        <Box sx={{ float: 'right' }}>
          {t('Fleet.My-Travels.Edit.State')} &nbsp;
          <Chip
            sx={{
              backgroundColor:
                valueProperty.estado.toUpperCase() === 'DISPONIBLE'
                  ? (theme) => theme.palette.success.main
                  : valueProperty.estado.toUpperCase() === 'NO DISPONIBLE' ||
                    valueProperty.estado.toUpperCase() === 'NO_DISPONIBLE'
                  ? (theme) => theme.palette.secondary.main
                  : valueProperty.estado.toUpperCase() === 'EN_VIAJE' ||
                    valueProperty.estado.toUpperCase() === 'EN VIAJE'
                  ? (theme) => theme.palette.warning.main
                  : valueProperty.estado.toUpperCase() === 'LICENCIA'
                  ? (theme) => theme.palette.error.main
                  : (theme) => theme.palette.secondary.main,
              color: '#fff',
              borderRadius: '6px',
            }}
            size="small"
            label={t(`Status.${valueProperty.estado}`)}
          />
        </Box>
      ) : (
        ''
      )}
      {property.id === 'remolque' && valueProperty !== null ? (
        <Box sx={{ float: 'right' }}>
          {t('Fleet.My-Travels.Edit.State')} &nbsp;
          <Chip
            sx={{
              backgroundColor:
                valueProperty.estado.toUpperCase() === 'DISPONIBLE'
                  ? (theme) => theme.palette.success.main
                  : valueProperty.estado.toUpperCase() === 'NO DISPONIBLE' ||
                    valueProperty.estado.toUpperCase() === 'NO_DISPONIBLE'
                  ? (theme) => theme.palette.secondary.main
                  : valueProperty.estado.toUpperCase() === 'EN_VIAJE' ||
                    valueProperty.estado.toUpperCase() === 'EN VIAJE'
                  ? (theme) => theme.palette.warning.main
                  : valueProperty.estado.toUpperCase() === 'LICENCIA'
                  ? (theme) => theme.palette.error.main
                  : (theme) => theme.palette.secondary.main,
              color: '#fff',
              borderRadius: '6px',
            }}
            size="small"
            label={t(`Status.${valueProperty.estado}`)}
          />
        </Box>
      ) : (
        ''
      )}
    </Grid>
  );
};

export const GetAutocompleteWorkflow = (property, formik, listItems, props) => {
  const handleChange = (event, value) => {
    formik.setFieldValue(property.id, value);
  };

  return (
    <Grid
      item
      key={property.id}
      xs={property.xs}
      md={property.md || property.xs}
      lg={property.lg || property.xs}
    >
      {!property?.hideLabel && (
        <CustomFormLabel htmlFor={property.id}>
          {property.label} {property.required ? '*' : ''}
        </CustomFormLabel>
      )}
      <Autocomplete
        sx={{ pb: 1 }}
        disablePortal
        id={property.id}
        options={listItems || []}
        multiple={(property?.multiple && !property.array) || false}
        disabled={property.disabled}
        fullWidth
        onBlur={formik.handleBlur}
        onChange={handleChange}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={Boolean(
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
            }
            size="medium"
            aria-label={property.label}
            label={(property.showLabel && property.label) || ''}
            placeholder={props.placeholder || ''}
          />
        )}
        {...props}
      />
    </Grid>
  );
};

export const GetAutoCompleteAsComponent = ({ property, formik, listItems, props }) => {
const valueProperty = 
  (property.multiple && property.array)
    ? formik.values[property.array]?.[property.key]?.[property.field] || []
    : formik.values[property.id] || null;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const listPallets = useSelector((state) => state.CustomizerReducer.typePallets);

  const handleChange = (event, value) => {
    formik.setFieldValue(property.id, value);

    if (property.field === 'selectProduct') {
      const cadena = property.id;
      const partes = cadena.split('.');
      const primeraParte = partes[0];

      if (value === null) {
        formik.setFieldValue(`${primeraParte}.sku`, '');
        formik.setFieldValue(`${primeraParte}.volumen`, '');
        formik.setFieldValue(`${primeraParte}.cantidadPallets`, '');
        formik.setFieldValue(`${primeraParte}.ncm`, null);
        formik.setFieldValue(`${primeraParte}.tipoPallet`, null);
        formik.setFieldValue(`${primeraParte}.valorMercaderia`, '');
        formik.setFieldValue(`${primeraParte}.monedaId`, undefined);
        return;
      }

      formik.setFieldValue(`${primeraParte}.sku`, value.sku);
      formik.setFieldValue(`${primeraParte}.volumen`, value.volumen);
      formik.setFieldValue(`${primeraParte}.cantidadPallets`, value.cantidad);
      const filterPallet = listPallets.find((pallet) => pallet.id === value.idTipoPallet);
      formik.setFieldValue(`${primeraParte}.tipoPallet`, filterPallet);
      formik.setFieldValue(`${primeraParte}.valorMercaderia`, value.precioProducto.monto);
      formik.setFieldValue(`${primeraParte}.monedaId`, value.precioProducto.moneda);
      formik.setFieldValue(`${primeraParte}.ncm`, {
        value: value.ncm.id,
        label: `${value.ncm.codigo} ${value.ncm.descripcion}`,
      });
    }
    if (property.id === 'tipoVehiculo') dispatch(chooseVehicle(value?.nombre));
  };

  return (
    <Grid item xs={property.xs} key={property.id} sx={{ m: 0 }}>
      {!property?.hideLabel && (
        <CustomFormLabel htmlFor={property.id}>
          {t(`${property.label}`)}
          {property.required ? '*' : ''}
        </CustomFormLabel>
      )}
      <Autocomplete
        sx={{ pb: 1, mt: 0 }}
        disablePortal
        id={property.id}
        options={listItems || []}
        multiple={(property?.multiple && !property.array) || false}
        value={property.multiple ? valueProperty || [] : valueProperty || null}
        disabled={property.disabled}
        fullWidth
        onBlur={formik.handleBlur}
        onChange={handleChange}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={Boolean(
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field] ?
                    formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field]
                    : formik.touched[property.id] && formik.errors[property.id],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]?
                  formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
                  : formik.touched[property.id] && formik.errors[property.id]
            }
            size="medium"
            aria-label={property.label}
            label={(property.showLabel && property.label) || ''}
            placeholder={props.placeholder || ''}
          />
        )}
        {...props}
      />
      {property.id === 'chofer' && valueProperty !== null ? (
        <Box sx={{ float: 'right' }}>
          {t('Fleet.My-Travels.Edit.State')} &nbsp;
          <Chip
            sx={{
              backgroundColor:
                valueProperty.estado.toUpperCase() === 'DISPONIBLE'
                  ? (theme) => theme.palette.success.main
                  : valueProperty.estado.toUpperCase() === 'NO DISPONIBLE' ||
                    valueProperty.estado.toUpperCase() === 'NO_DISPONIBLE'
                  ? (theme) => theme.palette.secondary.main
                  : valueProperty.estado.toUpperCase() === 'EN_VIAJE' ||
                    valueProperty.estado.toUpperCase() === 'EN VIAJE'
                  ? (theme) => theme.palette.warning.main
                  : valueProperty.estado.toUpperCase() === 'LICENCIA'
                  ? (theme) => theme.palette.error.main
                  : (theme) => theme.palette.secondary.main,
              color: '#fff',
              borderRadius: '6px',
            }}
            size="small"
            label={t(`Status.${valueProperty.estado}`)}
            /*             label={valueProperty.estado.toUpperCase()}
             */
          />
        </Box>
      ) : (
        ''
      )}
      {property.id === 'vehicle' && valueProperty !== null ? (
        <Box sx={{ float: 'right' }}>
          {t('Fleet.My-Travels.Edit.State')} &nbsp;
          <Chip
            sx={{
              backgroundColor:
                valueProperty.estado.toUpperCase() === 'DISPONIBLE'
                  ? (theme) => theme.palette.success.main
                  : valueProperty.estado.toUpperCase() === 'NO DISPONIBLE' ||
                    valueProperty.estado.toUpperCase() === 'NO_DISPONIBLE'
                  ? (theme) => theme.palette.secondary.main
                  : valueProperty.estado.toUpperCase() === 'EN_VIAJE' ||
                    valueProperty.estado.toUpperCase() === 'EN VIAJE'
                  ? (theme) => theme.palette.warning.main
                  : valueProperty.estado.toUpperCase() === 'LICENCIA'
                  ? (theme) => theme.palette.error.main
                  : (theme) => theme.palette.secondary.main,
              color: '#fff',
              borderRadius: '6px',
            }}
            size="small"
            /*             label={valueProperty.estado.toUpperCase()}
             */ label={t(`Status.${valueProperty.estado}`)}
          />
        </Box>
      ) : (
        ''
      )}
      {property.id === 'remolque' && valueProperty !== null ? (
        <Box sx={{ float: 'right' }}>
          {t('Fleet.My-Travels.Edit.State')} &nbsp;
          <Chip
            sx={{
              backgroundColor:
                valueProperty.estado.toUpperCase() === 'DISPONIBLE'
                  ? (theme) => theme.palette.success.main
                  : valueProperty.estado.toUpperCase() === 'NO DISPONIBLE' ||
                    valueProperty.estado.toUpperCase() === 'NO_DISPONIBLE'
                  ? (theme) => theme.palette.secondary.main
                  : valueProperty.estado.toUpperCase() === 'EN_VIAJE' ||
                    valueProperty.estado.toUpperCase() === 'EN VIAJE'
                  ? (theme) => theme.palette.warning.main
                  : valueProperty.estado.toUpperCase() === 'LICENCIA'
                  ? (theme) => theme.palette.error.main
                  : (theme) => theme.palette.secondary.main,
              color: '#fff',
              borderRadius: '6px',
            }}
            size="small"
            /*             label={valueProperty.estado.toUpperCase()}
             */ label={t(`Status.${valueProperty.estado}`)}
          />
        </Box>
      ) : (
        ''
      )}
    </Grid>
  );
};

export const getTextfieldDateType = (property, formik, props) => {
  return (
    <Grid item xs={property.xs} key={property.id}>
      <CustomFormLabel htmlFor={property.id}>{property.label}</CustomFormLabel>
      <TextField
        id={property.id}
        name={property.id}
        type="date"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[property.id]}
        error={Boolean(formik.touched[property.id] && formik.errors[property.id])}
        helperText={formik.touched[property.id] && formik.errors[property.id]}
        variant="outlined"
        fullWidth
        // required
        {...props}
      />
    </Grid>
  );
};

export const getTextfieldCustomDateType = (property, formik, dateProps = {}, inputProps = {}) => {
  const valueProperty =
    property?.multiple && property?.array
      ? formik?.values[property?.array][property?.key][property?.field]
      : formik?.values[property?.id];

  const formattedDate = moment(valueProperty).format('DD/MM/YYYY');
  return (
    <Grid item xs={property.xs} key={property?.id}>
      {!property?.hideLabel && (
        <CustomFormLabel htmlFor={property?.id}>{property?.label}</CustomFormLabel>
      )}
      <DatePicker
        id={`${property?.id}-date`}
        label={(property?.showLabel && property?.label) || ''}
        inputFormat="dd/MM/yyyy"
        value={formattedDate || ''}
        onChange={(newValue) => formik?.setFieldValue(property?.id, newValue)}
        onBlur={formik?.handleBlur}
        renderInput={(iProps) => (
          <TextField
            {...iProps}
            sx={{ width: '50%' }}
            variant="outlined"
            error={Boolean(
              !property.multiple
                ? formik?.touched[property?.id] && formik?.errors[property?.id]
                : formik?.touched[property?.array]?.[property?.key]?.[property?.field] &&
                    formik?.errors[property?.array]?.[property?.key]?.[property?.field],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
            }
            label={(property.showLabel && property.label) || ''}
            {...inputProps}
          />
        )}
        {...dateProps}
      />
      <TimePicker
        id={`${property.id}-time`}
        ampm={false}
        value={valueProperty || ''}
        onChange={(newValue) => formik.setFieldValue(property.id, newValue)}
        onBlur={formik.handleBlur}
        renderInput={(iProps) => (
          <TextField {...iProps} sx={{ width: '50%' }} variant="outlined" {...inputProps} />
        )}
        {...dateProps}
      />
    </Grid>
  );
};

export const TextfieldCustomOnlyDateType = ({
  property,
  formik,
  dateProps = {},
  inputProps = {},
  pickerKey,
}) => {
  const valueProperty =
    property.multiple && property.array
      ? formik.values[property.array][property.key][property.field]
      : formik.values[property.id];

  return (
    <Grid item xs={property.xs} key={property.id}>
      {!property.hideLabel && (
        <CustomFormLabel htmlFor={property.id}>{property.label}</CustomFormLabel>
      )}
      <DatePicker
        id={`${property.id}-date`}
        label={(property.showLabel && property.label) || ''}
        inputFormat="dd/MM/yyyy"
        value={valueProperty || ''}
        onChange={(newValue) => formik.setFieldValue(property.id, newValue)}
        onBlur={formik.handleBlur}
        key={pickerKey || `input-only-date-${property.id}-${new Date().toString()}`}
        renderInput={(iProps) => (
          <TextField
            {...iProps}
            fullWidth
            variant="outlined"
            error={Boolean(
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
            }
            label={(property.showLabel && property.label) || ''}
            {...inputProps}
          />
        )}
        {...dateProps}
      />
    </Grid>
  );
};

export const getTextfieldCustomOnlyDateType = (
  property,
  formik,
  dateProps = {},
  inputProps = {},
  pickerKey,
) => {
  const valueProperty =
    property.multiple && property.array
      ? formik.values[property.array][property.key][property.field]
      : formik.values[property.id];

  return (
    <Grid item xs={property.xs} key={property.id}>
      {!property.hideLabel && (
        <CustomFormLabel htmlFor={property.id}>{property.label}</CustomFormLabel>
      )}
      <DatePicker
        id={`${property.id}-date`}
        label={(property.showLabel && property.label) || ''}
        inputFormat="dd/MM/yyyy"
        value={valueProperty || ''}
        onChange={(newValue) => formik.setFieldValue(property.id, newValue)}
        onBlur={formik.handleBlur}
        key={pickerKey || `input-only-date-${property.id}-${new Date().toString()}`}
        renderInput={(iProps) => (
          <TextField
            {...iProps}
            fullWidth
            variant="outlined"
            error={Boolean(
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                    formik.errors[property.array]?.[property.key]?.[property.field],
            )}
            helperText={
              !property.multiple
                ? formik.touched[property.id] && formik.errors[property.id]
                : formik.touched[property.array]?.[property.key]?.[property.field] &&
                  formik.errors[property.array]?.[property.key]?.[property.field]
            }
            label={(property.showLabel && property.label) || ''}
            {...inputProps}
          />
        )}
        {...dateProps}
      />
    </Grid>
  );
};

export const getToggleButton = (property, valueOn, valueOff, formik, props, gripProps = {}) => {
  const valueProperty = property.multiple
    ? formik.values?.[property.array]?.[property.key]?.[property.field]
    : formik.values[property.id];

  const propertyId = property.multiple
    ? `${property.array}[${property.key}].['${property.field}']`
    : property.id;

  function handleChange() {
    formik.setFieldValue(propertyId, !valueProperty);
  }

  return (
    <Grid
      item
      xs={property.xs}
      key={property.id}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...gripProps}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        border={1}
        borderRadius={25}
        width={props && props.fullWidth ? 1 : 'fit-content'}
        borderColor={(theme) => alpha(theme.palette.text.disabled, 0.1)}
      >
        <IconButton onClick={handleChange}>
          <Box
            borderRadius="50%"
            border={0.5}
            sx={{
              p: '2px',
              borderColor: (theme) =>
                valueProperty ? theme.palette.primary.main : theme.palette.text.disabled,
            }}
          >
            <Avatar
              sx={{
                bgcolor: (theme) =>
                  valueProperty ? theme.palette.primary.main : theme.palette.text.disabled,
                width: 30,
                height: 30,
              }}
            >
              <Typography variant="h5">{valueProperty ? valueOn : valueOff}</Typography>
            </Avatar>
          </Box>
        </IconButton>
        <Typography variant="h6" mr={2} color={(theme) => alpha(theme.palette.text.disabled, 0.5)}>
          {property.label}
        </Typography>
      </Box>
    </Grid>
  );
};
