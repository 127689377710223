import React from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import { Warning } from '@mui/icons-material';
import CustomTextField from './forms/custom-elements/CustomTextField';

const CustomTextFieldWithValidation = ({
  value,
  name,
  onChange,
  placeholder = '',
  required = false,
  isSubmiting,
  helperText,
  error,
  type,
}) => {
  const showError = (required && value.trim() === '') || (isSubmiting && value.trim() === '');

  return (
    <CustomTextField
      variant="outlined"
      fullWidth
      name={name}
      size="small"
      value={value}
      error={error}
      helperText={helperText}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete="off"
      type={type}
      sx={{
        height: '60px',
        '.MuiInputBase-root': {
          height: '100%',
        },
        '.MuiInputBase-input': {
          height: '100%',
          padding: '16px',
        },
      }}
      InputProps={{
        inputProps: {
          maxLength: 254,
          min: type === 'number' ? 0 : undefined,
        },
        endAdornment: showError && value.trim() !== '' && (
          <InputAdornment position="end">
            <Tooltip
              title="Requerido"
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'crimson',
                    '& .MuiTooltip-arrow': {
                      color: 'crimson',
                    },
                  },
                },
              }}
            >
              <Warning sx={{ color: 'crimson' }} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomTextFieldWithValidation;
