import React, { useContext } from 'react';
import {
  Grid,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography
} from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

function transformarString(inputString) {
  return inputString.toLowerCase().replace(/_/g, ' ').replace(/(?:^|\s)\S/g, (a)=>   a.toUpperCase());
}

const SimpleChartBar = ({ title, 
  data, 
  xDataKey, 
  barDataKey, 
  strToolstip, 
  borderBarColor, 
  barColor,
  yAxisName,
  xAxisName
}) => {

  const maxWidth = 60;
  const barWidth = Math.min(maxWidth, 200 / data.length);
  const [t] = useTranslation();
  const {padMarByScreen, heightCardHeader, heightScreen, sizeFontCardHeader, mMainCard}= useContext(ScreenSizeContext)
  return (
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb:1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen,height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={title}
      />
      <Divider sx={{ backgroundColor: 'orange', marginBottom: heightScreen>900? 3:0, mx:2 }} />
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item  xs={12} sm={12} md={12}>
            {data && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 25,
                }}>
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey={xDataKey} axisLine={false} tickSize={5} 
                  label={{ value: t(`${xAxisName}`), position: 'insideBottomRight',offset:-20  }}
                  tick={{ fontSize: '1rem', fontWeight: 'bold' }}
                  tickFormatter={(value) => t(transformarString(value))}
                  />
                  <YAxis 
                  axisLine={false} tickLine={false} 
                  label={{ value: t(`${yAxisName}`), angle: -90, position: 'left', offset:-5}}
                  domain={ [0, 4] }
                  tickFormatter={(str)=>{              
                    if(str>=1000000000000) return Number.isInteger(str/1000000000000)? `${(str/1000000000000).toString()}B` : `${(str/1000000000000).toFixed(1)}B`

                    if(str>=1000000) return Number.isInteger(str/1000000)? `${(str/1000000).toString()}M`: `${(str/1000000).toFixed(1)}M`

                    if(str>=1000) return Number.isInteger(str/1000)? `${(str/1000).toString()}K`: `${(str/1000).toFixed(1)}K`
       
                    
                    return Number.isInteger(str)? str.toString(): str.toFixed(1)
                  }}
                  />
                  <Tooltip
                    content={(props) => <CustomTooltip strToolstip={strToolstip} {...props} />}
                  />
                 
                  <Bar dataKey={barDataKey}  stroke={borderBarColor} fill={barColor} barSize={barWidth} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SimpleChartBar;


const CustomTooltip = ({ active, payload, label, strToolstip }) => {
  const [t] = useTranslation();
  if (active && payload && payload.length) {
    return (
      <Card
        sx={{
          flexShrink: 0,
          p: 1,
          paddingBottom: 0,
          borderLeft: 6,
          borderLeftColor: payload[0].color,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        }}
      >
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={`${t(label)}`}
        />
        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ paddingLeft:1, paddingTop:0, margin: 0, marginBottom: -2 }}>
            <Typography>{`${strToolstip}: ${Number.isInteger(payload[0].value)? payload[0].value.toString(): payload[0].value.toFixed(1)}`}</Typography>
        
        </CardContent>
      </Card>
    );
  }
  return null;
}