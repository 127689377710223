import React, { useState } from 'react';
import { Box, Grid, IconButton, Chip } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import VehicleDetailsNoForm from '../../../../components/VehicleDetailsNoForm';
import PercentageDetails from '../../../../components/PercentageDetails';

const Carousel = ({ subTypes, infoSelectd, vehicle }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedChip, setSelectedChip] = React.useState(subTypes?.[0]?.id || '');

  const handleChipClick = (id) => {
    setSelectedChip(id);
    const selectedIndex = subTypes.findIndex((item) => item.id === id);
    setActiveStep(selectedIndex);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => {
      const newStep = (prevStep + 1) % subTypes.length;
      setSelectedChip(subTypes[newStep].id);
      return newStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      const newStep = (prevStep - 1 + subTypes.length) % subTypes.length;
      setSelectedChip(subTypes[newStep].id);
      return newStep;
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        paddingTop: 4,
      }}
    >
      <Grid container sx={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        {subTypes?.map((item) => (
          <Chip
            label={item.nombre}
            clickable
            onClick={() => handleChipClick(item.id)}
            color={selectedChip === item.id ? 'primary' : 'default'}
          />
        ))}
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Grid item xs={12} md={6}>
          <VehicleDetailsNoForm vehicle={vehicle} subType={subTypes[activeStep]} infoSelectd={infoSelectd}/>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <PercentageDetails
            loads={infoSelectd?.pedidos}
            vehicleSelected={vehicle}
            subVehicleSelected={subTypes[activeStep]}
          />
        </Grid>
      </Grid>

      {subTypes.length > 1 && (
        <Box sx={{ position: 'relative', width: '100%', marginBottom: '3rem' }}>
          <IconButton
            size="large"
            onClick={handleBack}
            sx={{ position: 'absolute', left: 0, color: '#ffbf3c' }}
          >
            <NavigateBeforeIcon fontSize="large" />
          </IconButton>

          <IconButton
            size="large"
            onClick={handleNext}
            sx={{ position: 'absolute', right: 0, color: '#ffbf3c' }}
          >
            <NavigateNextIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Carousel;
