/* eslint-disable no-param-reassign */
import axios from 'axios';
import { ORDER_PATH, KPI_CARGO_PATH , BASE_URL} from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const saveCargo = async (cargo) => {
  if(cargo.tipoFlete === ''){
    cargo.tipoFlete = null;
  }
  if (cargo.id) {
    const cargoId = cargo.id;
    delete cargo.id;
    return axios.put(`${ORDER_PATH}/editar`, cargo, { params: { id: cargoId } });
  }
  return axios.post(`${ORDER_PATH}/guardar`, cargo)
};

export const getAllCargo = async () => {
  const response = await axios.get(`${ORDER_PATH}/listado`);
  return response;
};

export const getAllCargoWithPagination = async (size, page = 0) => {
  return axios.get(`${ORDER_PATH}/listado?size=${size}&page=${page}`);
};

export const getLoadsWithSort = async (size, page, sortType1, sortType2, direction) => {
  if (sortType2) {
    return axios.get(
      `${ORDER_PATH}/listado?page=${page}&sort=${sortType1},${direction.toUpperCase()}&sort=${sortType2},${direction.toUpperCase()}&size=${size}`,
    );
  }
  return axios.get(
    `${ORDER_PATH}/listado?page=${page}&sort=${sortType1},${direction.toUpperCase()}&size=${size}`,
  );
};

export const getAllCargoWithQuery = async (searchParam) => {
  return axios.get(`${ORDER_PATH}/listado?searchParam=${encodeURIComponent(searchParam)}`);
};

export const cancel = async(id) =>{
  return axios.put(`${BASE_URL}/api/carga/cancelar?id=${id}`);
}

export const active = async(id)=>{
  return axios.put(`${BASE_URL}/api/carga/activar?id=${id}`);
}

export const getAllCargoWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  sortType2,
  direction,
) => {
  if (sortType2) {
    return axios.get(
      `${ORDER_PATH}/listado?searchParam=${encodeURIComponent(
        searchParam,
      )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}&sort=${sortType2},${direction.toUpperCase()}`,
    );
  }
  return axios.get(
    `${ORDER_PATH}/listado?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const getCargoSearch = async (filter) => {
  const data = {
    nroCarga: filter,
    razonSocialCliente: filter,
  };
  return axios.get(`${ORDER_PATH}/buscar`, { params: { ...data } });
};

export const getCargoList = async () => {
  return axios.get(`${ORDER_PATH}/listar-por-estado`);
};

export const getCargoListWithQuery = async (searchParam) => {
  return axios.get(`${ORDER_PATH}/listar-por-estado?search=${encodeURIComponent(searchParam)}`);
};

export const getCargoListWithFilter = async (loadType) => {
  return axios.get(`${ORDER_PATH}/listado/estado`, { params: { q: loadType } });
};

export const getCargoListWithFilterAndSearch = async (loadType, size, page, sortType1, sortType2, direction,search) => {
  return axios.get(`${ORDER_PATH}/listado/estado?page=${page}&sort=${sortType1},${direction.toUpperCase()}&size=${size}&q=${loadType}`);
};

export const getCargoListForEdit = async () => {
  return axios.get(`${ORDER_PATH}/listado/edicion`);
};

export const getAmountOfCargoListWithFilter = async (loadType) => {
  return axios.get(`${ORDER_PATH}/contadorEstado`, { params: { estado: loadType } });
};

export const changeCargoStatus = async (idCarga, status, cargo) => {
  return axios.post(`${ORDER_PATH}/guardar`, cargo, { params: { idCarga, status } });
};

export const getCargoHistory = async () => {
  return axios.get(`${ORDER_PATH}/historialPedidos`);
};

export const deleteLoad = async (id) => {
  return axios.delete(`${ORDER_PATH}/eliminar`, { params: { id } });
};

export const deleteLoad1 = async (id) => {
  return axios.delete(`${ORDER_PATH}/eliminar`, { params: { id } });
};

export const getLoad = async (id) => {
  return axios.get(`${ORDER_PATH}/${id}`);
};

export const getListLoad = async () => {
  return axios.get(`${KPI_CARGO_PATH}/listado`);
};

export const updateKpi = async () => {
  return axios.get(`${KPI_CARGO_PATH}/sincronizar`);
};

export const loadsAppointments = async () => {
  return axios.get(`${ORDER_PATH}/listar-por-TurnoCarga`);
};

export const downloadsAppointments = async () => {
  return axios.get(`${ORDER_PATH}/listar-por-TurnoDescarga`);
};