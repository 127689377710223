import React, { useState, useContext, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Hooks
import useAuth from '../../../hooks/useAuth';

// Componentes
import HeaderShortcuts from '../../../components/HeaderShortcuts/HeaderShorcuts';
import Consultation from '../../../components/consultation/Consultation';
import TranslationButton from '../../../components/Translation/TranslationButton';

// Mocks
import ShortcutItems from '../../../components/ShortcutItems';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

const Header = ({ sx, customClass, toggleMobileSidebar, setTourStart }) => {
  const { authenticatedData } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { widthScreen } = useContext(ScreenSizeContext);

  // const [showComponent, setShowComponent] = useState(false);
  const location = useLocation();

  const handleTour = () => {
    setTourStart(false);
    setTimeout(() => setTourStart(true), 100);
  };

  // useEffect(() => {
  //   if (location.pathname === '/carga/inicio') {
  //     setShowComponent(true);
  //   } else {
  //     setShowComponent(false);
  //   }
  // }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNavigate = () => {
    navigate('/follow-help-center');
  };

  return (
    <AppBar sx={sx} elevation={6} className={customClass}>
      <Toolbar>
        {!lgUp && (
          <IconButton id="menu" color="primary" onClick={toggleMobileSidebar}>
            <FeatherIcon icon="chevron-right" size={60} />
          </IconButton>
        )}

        <Box flexGrow={1} />
        <TranslationButton />
        <Box
          sx={
            lgUp
              ? {
                  width: '1px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  height: '25px',
                  ml: 1,
                  mr: 1,
                }
              : {
                  width: '1px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  height: '25px',
                  // ml: 1,
                  // mr: 1,
                }
          }
        />

        <Consultation />
        <Box
          sx={
            lgUp
              ? {
                  width: '1px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  height: '25px',
                  ml: 1,
                  mr: 1,
                }
              : {
                  width: '1px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  height: '25px',
                  // ml: 1,
                  // mr: 1,
                }
          }
        />

        <IconButton size="large" color="inherit" onClick={handleClickNavigate} id="life-buoy">
          {widthScreen >= 600 && (
            <Typography sx={lgUp ? { mr: 0.5 } : null}>{t('Help Center')}</Typography>
          )}
          <FeatherIcon icon="help-circle" width="20" height="20" />
        </IconButton>

        {
        // showComponent &&
         !smDown && (
          <>
            <Box
              sx={
                lgUp
                  ? {
                      width: '1px',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      height: '25px',
                      ml: 1,
                      mr: 1,
                    }
                  : {
                      width: '1px',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      height: '25px',
                      // ml: 1,
                      // mr: 1,
                    }
              }
            />
            <IconButton size="large" color="inherit" onClick={handleTour} id="life-buoy">
              {widthScreen >= 600 && (
                <Typography sx={lgUp ? { mr: 0.5 } : null}>{t('Tour.Lost')}</Typography>
              )}
              <FeatherIcon icon="flag" width="20" height="20" />
            </IconButton>
          </>
        )}

        <Box
          sx={
            lgUp
              ? {
                  width: '1px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  height: '25px',
                  ml: 1,
                  mr: 1,
                }
              : {
                  width: '1px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  height: '25px',
                  // ml: 1,
                  mr: 0.5,
                }
          }
        />

        {authenticatedData && (
          <>
            <Typography>{authenticatedData.nombre}</Typography>
            <Box
              sx={
                lgUp
                  ? {
                      width: '1px',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      height: '25px',
                      ml: 1,
                      mr: 1,
                    }
                  : {
                      width: '1px',
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      height: '25px',
                      ml: 0.5,
                      mr: 0.5,
                    }
              }
            />
          </>
        )}

        <IconButton sx={{ ml: 0 }} size="large" color="inherit" onClick={handleClick} id="menu">
          <FeatherIcon icon="align-justify" width="20" height="20" />
        </IconButton>

        <HeaderShortcuts
          items={ShortcutItems()}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          authenticatedData={authenticatedData}
        />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
  customClass: PropTypes.string,
  toggleMobileSidebar: PropTypes.func.isRequired,
  setTourStart: PropTypes.func.isRequired,
};

export default Header;
