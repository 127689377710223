import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import {
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import PageContainer from '../../components/container/PageContainer';
import TranslationButton from '../../components/Translation/TranslationButton';
import imgBackground from '../../assets/images/register/background-register.png';
import iconFollow from '../../assets/images/register/iconFollow.png';
import TermsAndConditionsModal from './components/TermsAndConditionsModal';

const BackgroundGrid = styled(Grid)({
    minHeight: '100vh',
    width: '100%',
    backgroundImage: `url(${imgBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    justifyContent: 'center',
  });

const AccountVerification = () => {
    const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [opentyc, setOpentyc] = useState(false);
  const navigate = useNavigate  ();

  const [t, i18n] = useTranslation();
  const theme = useTheme();


  return (
    <PageContainer title="Registro de usuario" description="this is innerpage">
      <Grid sx={{ position: 'absolute', top: 0, right: 0, marginRight: '1rem', marginTop: '1rem' }}>
        <TranslationButton />
      </Grid>
      <Grid sx={{ position: 'absolute', top: 0, left: 0, marginLeft: '2rem', marginTop: '1.5rem' }}/>
      <BackgroundGrid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" sx={{ m: 1, fontWeight: 500, mt: 5, mb: 5 }}>
          {t('Login.Your-account-is-being-verified...')}
        </Typography>
        <Button variant="contained" sx={{ mb: 3 }} onClick={() => {logout();navigate('/'); }}>
          {t('Button.Close')}
        </Button>
      </Grid>
      </BackgroundGrid>
      <Box
        sx={{
          bottom: 0,
          backgroundColor: '#FFFFFF',
          width: '100%',
          height: '60px',
        }}
      >
        <Grid container alignItems="center" sx={{ height: '100px' }}>
          <Grid item xs={6} sx={{ height: '45px', width: '300px', paddingLeft: '4rem' }}>
            <img
              src={iconFollow}
              alt="logo follow"
              style={{
                height: '100%',
                width: 'auto',
                display: 'block',
              }}
            />
          </Grid>
          <Grid item xs={3} textAlign="end" paddingRight="1rem">
            <Typography>+5492615885152</Typography>
          </Grid>

          <Grid item xs={3} borderLeft="2px solid #FFB629" paddingLeft="1rem">
            <Typography>info@followlsn.com</Typography>
          </Grid>
        </Grid>
      </Box>

      <TermsAndConditionsModal open={opentyc} onClose={() => setOpentyc(false)} />
      <Backdrop sx={{ color: '#fff', zIndex: theme.zIndex.modal + 1 }} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
   
    </PageContainer>
  )
}

export default AccountVerification