import axios from "axios";
import { ACTIVOS_PATH, KPI_ACTIVOS_PATH } from "../config";
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getAllActivos = async (size, page = 0) => {
    return axios.get(`${ACTIVOS_PATH}/listado?size=${size}&page=${page}`);
};

export const getAllActivosKPI = async (size, page = 0) => {
  return axios.get(`${KPI_ACTIVOS_PATH}/listado`);
};

export const getActivosList = async (size, page) => {
  return axios.get(`${ACTIVOS_PATH}/listado?size=${size}&page=${page}`);
};

// export const getAllActivos = async () => {
//     return axios.get(`${ACTIVOS_PATH}/`)
// }