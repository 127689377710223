import { format, parseISO, parse } from 'date-fns';

const currentDate = new Date();
const currentDay = currentDate.getDate();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
const currentHours = currentDate.getHours();

export const oneDayData = (data) => {
  const last24HoursData = [];
  for (let i = 0; i < 24; i++) {
    let hour = currentHours - i;
    let day = currentDay;
    if (hour < 0) {
      hour += 24;
      day--;
    }
    const formattedHour = `${hour.toString().padStart(2, '0')}:00`;
    const hourData = {
      date: formattedHour,
      cantidad: 0,
      monto: 0,
      promedio: 0,
    };
    last24HoursData.unshift(hourData);
  }
  data.forEach((item) => {
    const fecha = new Date(item.fecha);
    fecha.setHours(fecha.getHours() + 3);
    const hour = fecha.getHours();
    const twentyFourHoursAgo = new Date(currentDate);
    twentyFourHoursAgo.setHours(currentHours - 24);

    if (fecha >= twentyFourHoursAgo) {
      const hourData = last24HoursData.find(
        (e) => e.date === `${hour.toString().padStart(2, '0')}:00`,
      );
      if (hourData) {
        hourData.cantidad++;
        hourData.monto += item.monto;
        hourData.promedio = hourData.monto / hourData.cantidad;
      }
    }
  });

  return last24HoursData;
};

// export const delayedOneDayData = (data) => {
//   const demorados = [];

//   for (let i = 0; i < 24; i++) {
//     let hour = currentHours - i;
//     let day = currentDay;
//     if (hour < 0) {
//       hour += 24;
//       day--;
//     }
//     const formattedHour = `${hour.toString().padStart(2, '0')}:00`;
//     const hourData = {
//       date: formattedHour,
//       fechaFin: data.fechaFin,
//       llegada: data.llegada,
//     };
//     demorados.unshift(hourData);
//   }

//   data.forEach(item => {
//     const fecha = new Date(item.fecha);
//     fecha.setHours(fecha.getHours() + 3);
//     const hour = fecha.getHours();
//     const fechaFin = new Date(item.fechaFin);
//     const llegada = new Date(item.llegada);

//     if (fechaFin && fechaFin > llegada) {
//       demorados.push({
//         date: `${hour.toString().padStart(2, '0')}:00`,
//         fechaFin: item.fechaFin,
//         llegada: item.llegada,
//       });
//     }
//   });

//   console.log(demorados);
//   return demorados;
// };

export const sevenDaysData = (seven) => {
  const last7DaysData = [];
  let thereIsData = false;
  for (let i = 0; i < 7; i++) {
    let day = currentDay - i;
    let month = currentMonth + 1;
    let year = currentYear;

    if (day <= 0) {
      const prevMonthDate = new Date(year, month - 1, 0);
      day += prevMonthDate.getDate();
      month--;
      if (month <= 0) {
        month = 12;
        year--;
      }
    }

    const formattedHour = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    const dayData = {
      date: formattedHour,
      cantidad: 0,
      monto: 0,
      promedio: 0,
    };

    last7DaysData.unshift(dayData);
  }
  seven.forEach((item) => {
    const fecha = new Date(item.fecha);
    fecha.setHours(fecha.getHours() + 3);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();

    const dayData = last7DaysData.find((data) => {
      const [dayDt, monthData, yearData] = data.date.split('/').map(Number);
      return year === yearData && month === monthData && day === dayDt;
    });

    if (dayData) {
      dayData.cantidad++;
      dayData.monto += item.monto;
      dayData.promedio = dayData.monto / dayData.cantidad;
      thereIsData = true;
    }
  });
  // console.log('TARDE',demorados);
  return thereIsData ? last7DaysData : null;
};

export const delayedSevenDaysData = (seven) => {
  const last7DaysData = [];
  let thereIsData = false;
  for (let i = 0; i < 7; i++) {
    let day = currentDay - i;
    let month = currentMonth + 1;
    let year = currentYear;

    if (day <= 0) {
      const prevMonthDate = new Date(year, month - 1, 0);
      day += prevMonthDate.getDate();
      month--;
      if (month <= 0) {
        month = 12;
        year--;
      }
    }

    const formattedHour = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    const dayData = {
      date: formattedHour,
      cantidad: 0,
    };

    last7DaysData.unshift(dayData);
  }

  seven.forEach((item) => {
    const fechaLlegada = new Date(item.llegada);
    const fechaFin = new Date(item.fechaFin);
    const year = fechaLlegada.getFullYear();
    const month = fechaLlegada.getMonth() + 1;
    const day = fechaLlegada.getDate();

    if (fechaFin === null || fechaFin > fechaLlegada) {
      const dayData = last7DaysData.find((data) => {
        const [dayDt, monthData, yearData] = data.date.split('/').map(Number);
        return year === yearData && month === monthData && day === dayDt;
      });

      if (dayData) {
        dayData.cantidad++;
        thereIsData = true;
      }
    }
  });

  return thereIsData ? last7DaysData : null;
};

export const sevenDaysOrderData = (seven) => {
  const last7DaysData = [];
  let thereIsData = false;
  for (let i = 0; i < 7; i++) {
    let day = currentDay - i;
    let month = currentMonth + 1;
    let year = currentYear;

    if (day <= 0) {
      const prevMonthDate = new Date(year, month - 1, 0);
      day += prevMonthDate.getDate();
      month--;
      if (month <= 0) {
        month = 12;
        year--;
      }
    }

    const formattedHour = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    const dayData = {
      date: formattedHour,
      cantidad: 0,
    };

    last7DaysData.unshift(dayData);
  }

  seven.forEach((item) => {
    const fechaLlegada = new Date(item.entrega);
    const year = fechaLlegada.getFullYear();
    const month = fechaLlegada.getMonth() + 1;
    const day = fechaLlegada.getDate();

    if (fechaLlegada && item.estado === 'FINALIZADO') {
      const dayData = last7DaysData.find((data) => {
        const [dayDt, monthData, yearData] = data.date.split('/').map(Number);
        return year === yearData && month === monthData && day === dayDt;
      });

      if (dayData) {
        dayData.cantidad++;
        thereIsData = true;
      }
    }
  });
  return thereIsData ? last7DaysData : null;
};

export const lastMonthData = (dataToMonth) => {
  const lastMont = [];
  let thereIsData = false;
  const prevMonth = new Date(currentDate);
  prevMonth.setMonth(prevMonth.getMonth() - 1);
  const differenceInMilliseconds = currentDate - prevMonth;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  for (let i = 0; i <= differenceInDays; i++) {
    let day = currentDay - i;
    let month = currentMonth + 1;
    let year = currentYear;
    if (day <= 0) {
      const prevMonthDate = new Date(year, month - 1, 0);
      day += prevMonthDate.getDate();
      month--;
      if (month <= 0) {
        month = 12;
        year--;
      }
    }
    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    const dayData = {
      date: formattedDate,
      cantidad: 0,
      monto: 0,
      promedio: 0,
    };
    lastMont.unshift(dayData);
  }

  dataToMonth.forEach((item) => {
    const fecha = new Date(item.fecha);
    fecha.setHours(fecha.getHours() + 3);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();

    const dayData = lastMont.find((data) => {
      const [dayDt, monthData, yearData] = data.date.split('/').map(Number);
      return year === yearData && month === monthData && day === dayDt;
    });

    if (dayData) {
      dayData.cantidad++;
      dayData.monto += item.monto;
      dayData.promedio = dayData.monto / dayData.cantidad;
      thereIsData = true;
    }
  });

  return thereIsData ? lastMont : null;
};

export const delayedLastMonthData = (dataToMonth) => {
  const lastMonth = [];
  let thereIsData = false;
  const prevMonth = new Date(currentDate);
  prevMonth.setMonth(prevMonth.getMonth() - 1);
  const differenceInMilliseconds = currentDate - prevMonth;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  for (let i = 0; i <= differenceInDays; i++) {
    let day = currentDay - i;
    let month = currentMonth + 1;
    let year = currentYear;
    if (day <= 0) {
      const prevMonthDate = new Date(year, month - 1, 0);
      day += prevMonthDate.getDate();
      month--;
      if (month <= 0) {
        month = 12;
        year--;
      }
    }
    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    const dayData = {
      date: formattedDate,
      cantidad: 0,
    };
    lastMonth.unshift(dayData);
  }

  dataToMonth.forEach((item) => {
    const fechaLlegada = new Date(item.llegada);
    const fechaFin = new Date(item.fechaFin);
    if (fechaFin === null || fechaFin > fechaLlegada) {
      const dayData = lastMonth.find((data) => {
        const [dayDt, monthData, yearData] = data.date.split('/').map(Number);
        return (
          fechaLlegada.getFullYear() === yearData &&
          fechaLlegada.getMonth() + 1 === monthData &&
          fechaLlegada.getDate() === dayDt
        );
      });

      if (dayData) {
        dayData.cantidad++;
        thereIsData = true;
      }
    }
  });

  return thereIsData ? lastMonth : null;
};

export const lastMonthOrderData = (dataToMonth) => {
  const lastMonth = [];
  let thereIsData = false;
  const prevMonth = new Date(currentDate);
  prevMonth.setMonth(prevMonth.getMonth() - 1);
  const differenceInMilliseconds = currentDate - prevMonth;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  for (let i = 0; i <= differenceInDays; i++) {
    let day = currentDay - i;
    let month = currentMonth + 1;
    let year = currentYear;
    if (day <= 0) {
      const prevMonthDate = new Date(year, month - 1, 0);
      day += prevMonthDate.getDate();
      month--;
      if (month <= 0) {
        month = 12;
        year--;
      }
    }
    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    const dayData = {
      date: formattedDate,
      cantidad: 0,
    };
    lastMonth.unshift(dayData);
  }

  dataToMonth.forEach((item) => {
    const fechaLlegada = new Date(item.entrega);
    if (fechaLlegada && item.estado === 'FINALIZADO') {
      const dayData = lastMonth.find((data) => {
        const [dayDt, monthData, yearData] = data.date.split('/').map(Number);
        return (
          fechaLlegada.getFullYear() === yearData &&
          fechaLlegada.getMonth() + 1 === monthData &&
          fechaLlegada.getDate() === dayDt
        );
      });
      if (dayData) {
        dayData.cantidad++;
        thereIsData = true;
      }
    }
  });
  return thereIsData ? lastMonth : null;
};

export const lastYearData = (dataToLastYear) => {
  const lastYear = new Date(currentDate);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setMonth(lastYear.getMonth() + 1);
  const lastYearArray = [];
  let thereIsData = false;
  for (let i = 0; i < 12; i++) {
    const year = lastYear.getFullYear();
    const month = lastYear.getMonth() + 1;
    const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
    const monthData = {
      date: formattedDate,
      cantidad: 0,
      monto: 0,
      promedio: 0,
    };
    lastYearArray.push(monthData);
    lastYear.setMonth(lastYear.getMonth() + 1);
  }

  dataToLastYear.forEach((item) => {
    const fecha = new Date(item.fecha);
    fecha.setHours(fecha.getHours() + 3);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;

    const monthData = lastYearArray.find((data) => {
      const [monthDt, yearData] = data.date.split('/').map(Number);
      return month === monthDt && year === yearData;
    });

    if (monthData) {
      monthData.cantidad++;
      monthData.monto += item.monto;
      monthData.promedio = monthData.monto / monthData.cantidad;
      thereIsData = true;
    }
  });

  return thereIsData ? lastYearArray : null;
};

export const delayedLastYearData = (dataToLastYear) => {
  const lastYear = new Date(currentDate);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setMonth(lastYear.getMonth() + 1);
  const lastYearArray = [];
  let thereIsData = false;

  for (let i = 0; i < 12; i++) {
    const year = lastYear.getFullYear();
    const month = lastYear.getMonth() + 1;
    const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
    const monthData = {
      date: formattedDate,
      cantidad: 0,
    };
    lastYearArray.push(monthData);
    lastYear.setMonth(lastYear.getMonth() + 1);
  }

  dataToLastYear.forEach((item) => {
    const fechaLlegada = new Date(item.llegada);
    const fechaFin = new Date(item.fechaFin);
    if (fechaFin === null || fechaFin > fechaLlegada) {
      const monthData = lastYearArray.find((data) => {
        const [monthDt, yearData] = data.date.split('/').map(Number);
        return fechaLlegada.getMonth() + 1 === monthDt && fechaLlegada.getFullYear() === yearData;
      });

      if (monthData) {
        monthData.cantidad++;
        thereIsData = true;
      }
    }
  });

  return thereIsData ? lastYearArray : null;
};

export const lastYearOrderData = (dataToLastYear) => {
  const lastYear = new Date(currentDate);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setMonth(lastYear.getMonth() + 1);
  const lastYearArray = [];
  let thereIsData = false;

  for (let i = 0; i < 12; i++) {
    const year = lastYear.getFullYear();
    const month = lastYear.getMonth() + 1;
    const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
    const monthData = {
      date: formattedDate,
      cantidad: 0,
    };
    lastYearArray.push(monthData);
    lastYear.setMonth(lastYear.getMonth() + 1);
  }

  dataToLastYear?.forEach((item) => {
    const fechaLlegada = new Date(item.entrega);
    // const fechaFin = new Date(item.fechaFin);
    if (fechaLlegada && item.estado === 'FINALIZADO') {
      const monthData = lastYearArray.find((data) => {
        const [monthDt, yearData] = data.date.split('/').map(Number);
        return fechaLlegada.getMonth() + 1 === monthDt && fechaLlegada.getFullYear() === yearData;
      });

      if (monthData) {
        monthData.cantidad++;
        thereIsData = true;
      }
    }
  });
  return thereIsData ? lastYearArray : null;
};

export const maxData = (dataToMax, startDate) => {
  if (!dataToMax || !startDate) return [];

  const firstDate = new Date(startDate);
  const maxArray = [];
  let thereIsData = false;

  const currentDatePointer = new Date(firstDate);

  while (currentDatePointer <= currentDate) {
    const year = currentDatePointer.getFullYear();
    const month = currentDatePointer.getMonth() + 1;

    const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
    const monthData = {
      date: formattedDate,
      cantidad: 0,
      monto: 0,
      promedio: 0,
    };
    maxArray.push(monthData);

    currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
  }

  dataToMax.forEach((item) => {
    const fecha = new Date(item.fecha);
    fecha.setHours(fecha.getHours() + 3);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;

    const monthData = maxArray.find(
      (data) => data.date === `${month.toString().padStart(2, '0')}/${year}`,
    );

    if (monthData) {
      monthData.cantidad++;
      monthData.monto += item.monto;
      monthData.promedio = monthData.monto / monthData.cantidad;
      thereIsData = true;
    }
  });

  return thereIsData ? maxArray : null;
};

export const delayedMaxData = (dataToMax, startDate) => {
  if (!dataToMax || !startDate) return [];

  const firstDate = new Date(startDate);
  const maxArray = [];
  let thereIsData = false;

  const currentDatePointer = new Date(firstDate);

  while (currentDatePointer <= currentDate) {
    const year = currentDatePointer.getFullYear();
    const month = currentDatePointer.getMonth() + 1;

    const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
    const monthData = {
      date: formattedDate,
      cantidad: 0,
    };
    maxArray.push(monthData);

    currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
  }

  dataToMax.forEach((item) => {
    const fecha = new Date(item.llegada);
    fecha.setHours(fecha.getHours() + 3);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const fechaLlegada = new Date(item.llegada);
    const fechaFin = new Date(item.fechaFin);
    if (fechaFin === null || fechaFin > fechaLlegada) {
      const monthData = maxArray.find(
        (data) => data.date === `${month.toString().padStart(2, '0')}/${year}`,
      );

      if (monthData) {
        monthData.cantidad++;
        thereIsData = true;
      }
    }
  });

  return thereIsData ? maxArray : null;
};

export const maxOrderData = (dataToMax, startDate) => {
  if (!dataToMax || !startDate) return [];

  const firstDate = new Date(startDate);
  const maxArray = [];
  let thereIsData = false;

  const currentDatePointer = new Date(firstDate);

  while (currentDatePointer <= currentDate) {
    const year = currentDatePointer.getFullYear();
    const month = currentDatePointer.getMonth() + 1;

    const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
    const monthData = {
      date: formattedDate,
      cantidad: 0,
    };
    maxArray.push(monthData);

    currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
  }

  dataToMax.forEach((item) => {
    const fecha = new Date(item.entrega);
    fecha.setHours(fecha.getHours() + 3);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const fechaLlegada = new Date(item.fecha);
    // const fechaFin = new Date(item.fechaFin);
    if (fechaLlegada && item.estado === 'FINALIZADO') {
      const monthData = maxArray.find(
        (data) => data.date === `${month.toString().padStart(2, '0')}/${year}`,
      );

      if (monthData) {
        monthData.cantidad++;
        thereIsData = true;
      }
    }
  });

  return thereIsData ? maxArray : null;
};

export const expensesTotal = (gastos) => {
  const data = gastos.map((gasto) => ({
    fecha: format(parseISO(gasto.fecha), 'MMM dd, yyyy'),
    monto: gasto.monto,
    tipoDeGasto: gasto.tipoDeGasto,
  }));
  const gastosAgregados = {};
  data.forEach((dato) => {
    const fecha = parse(dato.fecha, 'MMM dd, yyyy', new Date());
    const fechaFormateada = format(fecha, 'MMM dd, yyyy');

    if (!gastosAgregados[fechaFormateada]) {
      gastosAgregados[fechaFormateada] = {};
    }

    if (!gastosAgregados[fechaFormateada][dato.tipoDeGasto]) {
      gastosAgregados[fechaFormateada][dato.tipoDeGasto] = 0;
    }

    gastosAgregados[fechaFormateada][dato.tipoDeGasto] += dato.monto;
  });
  const resultado = Object.entries(gastosAgregados).map(([fecha, tiposDeGastos]) => {
    return {
      fecha,
      ...tiposDeGastos,
    };
  });
  resultado.sort(
    (a, b) =>
      parse(a.fecha, 'MMM dd, yyyy', new Date()) - parse(b.fecha, 'MMM dd, yyyy', new Date()),
  );

  return resultado;
};

function obtenerNumeroMes(nombreMes) {
  const meses = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return meses.indexOf(nombreMes);
}

export function expensesPerMonth(array, date) {
  const [mes, anio] = date.split('/');
  const fechaFiltro = new Date(anio, parseInt(mes, 10) - 1);
  const resultado = array.filter((objeto) => {
    const [nombreMes, dia, anioObjeto] = objeto.fecha.split(' ');
    const fechaObjeto = new Date(anioObjeto, obtenerNumeroMes(nombreMes));

    return (
      fechaObjeto.getMonth() === fechaFiltro.getMonth() &&
      fechaObjeto.getFullYear() === fechaFiltro.getFullYear()
    );
  });

  return resultado;
}

export const classifyAssets = (datos) => {
  const resultado = Object.values(
    datos.reduce((acumulador, elemento) => {
      const { tipoActivo, estado } = elemento;

      // Inicializa las propiedades si aún no existen
      if (!acumulador[tipoActivo]) {
        // eslint-disable-next-line no-param-reassign
        acumulador[tipoActivo] = {
          tipoActivo,
          disponible: 0,
          pendiente: 0,
        };
      }

      // Incrementa la cuenta según el estado
      if (estado === 'PENDIENTE' || estado === 'NO_DISPONIBLE') {
        // eslint-disable-next-line no-param-reassign
        acumulador[tipoActivo].pendiente++;
      } else if (estado === 'DISPONIBLE') {
        // eslint-disable-next-line no-param-reassign
        acumulador[tipoActivo].disponible++;
      }

      return acumulador;
    }, {}),
  );
  return resultado;
};

// funciones para manipular la data para los KPI propios

export const lastSevenDaysOwnKPI = (data, adder, agrupador) => {
  let last7DaysData = [];
  let thereIsData = false;

  if (agrupador === 'Tiempo') {
    for (let i = 0; i < 7; i++) {
      let day = currentDay - i;
      let month = currentMonth + 1;
      let year = currentYear;

      if (day <= 0) {
        const prevMonthDate = new Date(year, month - 1, 0);
        day += prevMonthDate.getDate();
        month--;
        if (month <= 0) {
          month = 12;
          year--;
        }
      }

      const formattedHour = `${day.toString().padStart(2, '0')}/${month
        .toString()
        .padStart(2, '0')}/${year}`;

      const element = {
        seccion: formattedHour,
        cantidad: 0,
      };

      last7DaysData.unshift(element);
    }

    data.forEach((item) => {
      const fecha = new Date(item.fecha);
      fecha.setHours(fecha.getHours() + 3);
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;
      const day = fecha.getDate();

      const element = last7DaysData.find((e) => {
        const [dayDt, monthData, yearData] = e.seccion.split('/').map(Number);
        return year === yearData && month === monthData && day === dayDt;
      });

      if (element) {
        if (adder === 'km') {
          element.cantidad += parseFloat(item.km);
        } else {
          element.cantidad++;
        }
        thereIsData = true;
      }
    });
  } else {
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const startDateOfWeek = new Date(currentDate - oneWeek);

    const filteredData = data.filter((obj) => {
      const objDate = new Date(obj.fecha);
      return objDate >= startDateOfWeek && objDate <= currentDate;
    });

    const conteo = {};

    filteredData.forEach((obj) => {
      const seccion = obj[agrupador];
      if (seccion) {
        thereIsData = true;
        if (conteo[seccion]) {
          if (adder === 'km') {
            conteo[seccion] += parseFloat(obj.km);
          } else {
            conteo[seccion]++;
          }
        } else if (adder === 'km') {
          conteo[seccion] = parseFloat(obj.km);
        } else {
          conteo[seccion] = 1;
        }
      }
    });

    last7DaysData = Object.keys(conteo).map((secKey) => {
      return { seccion: secKey, cantidad: conteo[secKey] };
    });
  }
  return thereIsData ? last7DaysData : null;
};

export const lastMonthDataOwnKPI = (data, adder, agrupador) => {
  let lastMont = [];
  let thereIsData = false;

  const prevMonth = new Date(currentDate);
  prevMonth.setMonth(prevMonth.getMonth() - 1);
  const differenceInMilliseconds = currentDate - prevMonth;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  if (agrupador === 'Tiempo') {
    for (let i = 0; i <= differenceInDays; i++) {
      let day = currentDay - i;
      let month = currentMonth + 1;
      let year = currentYear;
      if (day <= 0) {
        const prevMonthDate = new Date(year, month - 1, 0);
        day += prevMonthDate.getDate();
        month--;
        if (month <= 0) {
          month = 12;
          year--;
        }
      }
      const formattedDate = `${day.toString().padStart(2, '0')}/${month
        .toString()
        .padStart(2, '0')}/${year}`;
      const element = {
        seccion: formattedDate,
        cantidad: 0,
      };
      lastMont.unshift(element);
    }

    data.forEach((item) => {
      const fecha = new Date(item.fecha);
      fecha.setHours(fecha.getHours() + 3);
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;
      const day = fecha.getDate();

      const element = lastMont.find((e) => {
        const [dayDt, monthData, yearData] = e.seccion.split('/').map(Number);
        return year === yearData && month === monthData && day === dayDt;
      });

      if (element) {
        if (adder === 'km') {
          element.cantidad += parseFloat(item.km);
        } else {
          element.cantidad++;
        }
        thereIsData = true;
      }
    });
  } else {
    const filteredData = data.filter((obj) => {
      const objDate = new Date(obj.fecha);
      return objDate >= prevMonth && objDate <= currentDate;
    });

    const conteo = {};

    filteredData.forEach((obj) => {
      const seccion = obj[agrupador];
      if (seccion) {
        thereIsData = true;
        if (conteo[seccion]) {
          if (adder === 'km') {
            conteo[seccion] += parseFloat(obj.km);
          } else {
            conteo[seccion]++;
          }
        } else if (adder === 'km') {
          conteo[seccion] = parseFloat(obj.km);
        } else {
          conteo[seccion] = 1;
        }
      }
    });

    lastMont = Object.keys(conteo).map((secKey) => {
      return { seccion: secKey, cantidad: conteo[secKey] };
    });
  }
  return thereIsData ? lastMont : null;
};

export const lastYearDataOwnKPI = (data, adder, agrupador) => {
  let lastYearArray = [];
  let thereIsData = false;
  const lastYear = new Date(currentDate);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setMonth(lastYear.getMonth() + 1);

  if (agrupador === 'Tiempo') {
    for (let i = 0; i < 12; i++) {
      const year = lastYear.getFullYear();
      const month = lastYear.getMonth() + 1;
      const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
      const element = {
        seccion: formattedDate,
        cantidad: 0,
      };
      lastYearArray.push(element);
      lastYear.setMonth(lastYear.getMonth() + 1);
    }

    data.forEach((item) => {
      const fecha = new Date(item.fecha);
      fecha.setHours(fecha.getHours() + 3);
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;

      const element = lastYearArray.find((e) => {
        const [monthDt, yearData] = e.seccion.split('/').map(Number);
        return month === monthDt && year === yearData;
      });

      if (element) {
        if (adder === 'km') {
          element.cantidad += parseFloat(item.km);
        } else {
          element.cantidad++;
        }
        thereIsData = true;
      }
    });
  } else {
    const filteredData = data.filter((obj) => {
      const objDate = new Date(obj.fecha);
      return objDate >= lastYear && objDate <= currentDate;
    });

    const conteo = {};

    filteredData.forEach((obj) => {
      const seccion = obj[agrupador];
      if (seccion) {
        thereIsData = true;
        if (conteo[seccion]) {
          if (adder === 'km') {
            conteo[seccion] += parseFloat(obj.km);
          } else {
            conteo[seccion]++;
          }
        } else if (adder === 'km') {
          conteo[seccion] = parseFloat(obj.km);
        } else {
          conteo[seccion] = 1;
        }
      }
    });

    lastYearArray = Object.keys(conteo).map((secKey) => {
      return { seccion: secKey, cantidad: conteo[secKey] };
    });
  }
  return thereIsData ? lastYearArray : null;
};

export const maxDataOwnKPI = (data, adder, agrupador, startDate) => {
  
  if (!data || !startDate) return [];
  const firstDate = new Date(startDate);
  
  let maxArray = [];
  let thereIsData = false;
  const currentDatePointer = new Date(firstDate);
  

  if (agrupador === 'Tiempo') {
    while (currentDatePointer <= currentDate) {
      const year = currentDatePointer.getFullYear();
      const month = currentDatePointer.getMonth() + 1;
      const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
      const element = {
        seccion: formattedDate,
        cantidad: 0,
      };
      maxArray.push(element);

      currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
    }

    data.forEach((item) => {
      const fecha = new Date(item.fecha);
      fecha.setHours(fecha.getHours() + 3);
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;

      const element = maxArray.find(
        (e) => e.seccion === `${month.toString().padStart(2, '0')}/${year}`,
      );

      if (element) {
        if (adder === 'km') {
          element.cantidad += parseFloat(item.km);
        } else {
          element.cantidad++;
        }
        thereIsData = true;
      }
    });
  } else {
    const filteredData = data.filter((obj) => {
      const objDate = new Date(obj.fecha);
      return objDate >= firstDate && objDate <= currentDate;
    });

    const conteo = {};

    filteredData.forEach((obj) => {
      const seccion = obj[agrupador];
      if (seccion) {
        thereIsData = true;
        if (conteo[seccion]) {
          if (adder === 'km') {
            conteo[seccion] += parseFloat(obj.km);
          } else {
            conteo[seccion]++;
          }
        } else if (adder === 'km') {
          conteo[seccion] = parseFloat(obj.km);
        } else {
          conteo[seccion] = 1;
        }
      }
    });

    maxArray = Object.keys(conteo).map((secKey) => {
      return { seccion: secKey, cantidad: conteo[secKey] };
    });
  }
  return thereIsData ? maxArray : null;
};
