import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Typography,
  Box,
  useTheme,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Rating,
} from '@mui/material';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { useSnackbar } from 'notistack';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/fleet-layout/breadcrumb/Breadcrumb';
import TableCard from '../../components/table-card/TableCard';
import { createRatingCustomers, getCustomersOrders } from '../../api/score';

const CustomersRatings = () => {
  const [t] = useTranslation();
  const theme = useTheme();
  const [scoreData, setScoreData] = useState(null);
  const [puntaje, setPuntaje] = useState(0);
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idCliente = searchParams.get('idCliente');
  const idCarga = searchParams.get('idCarga');

  const BCrumb = [
    {
      to: '/',
      title: 'Home',
    },
    {
      title: 'Calificar',
    },
  ];

  const headCells = [
    {
      id: 'nroPedido',
      numeric: true,
      disablePadding: false,
      label: t('TableHeader.LoadNumber'),
      width: '25%',
    },
    {
      id: 'nombreEmpresa',
      numeric: true,
      disablePadding: false,
      label: t('Types.Empresa'),
      width: '25%',
    },
    {
      id: 'calificar',
      numeric: true,
      disablePadding: false,
      disableSort: true,
      label: t('ActionListTable.Score'),
      width: '25%',
    },
  ];

  const getCustomersOrdersBy = async (cliente, pedido) => {
    const { data } = await getCustomersOrders(cliente, pedido);
    setScoreData(data);
  };

  const scoring = async (value, pedido) => {
    try {
      const { data } = await createRatingCustomers({
        descripcion: '',
        idPedido: pedido.idPedido,
        idEmpresaDestino: pedido.idEmpresaDestino,
        idCliente,
        idCarga,
        isCliente: pedido.isCliente,
        puntos: value,
      });
      setPuntaje(value);
      showMessage('Calificado correctamente', 'success');
    } catch (error) {
      console.log(error.response);
    };
  };

  useEffect(() => {
    getCustomersOrdersBy(idCliente, id);
  }, []);

  useEffect(() => {
    if (scoreData?.puntos) {
      setPuntaje(scoreData?.puntos);
    }
  }, [scoreData]);

  return (
    <PageContainer title="Calificar">
      <Breadcrumb items={BCrumb} />
      <Card sx={{ p: 0, width: '98%' }}>
        <Box sx={{ bgcolor: 'primary.main', px: 4, py: 3, width: '100%' }}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb={1}>
            <Typography variant="h2" lineHeight="24px" mr={2} color="white">
            {t('ActionListTable.Score')}
            </Typography>
            <StarHalfIcon style={{ color: 'white', fontSize: '36px' }} />
          </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <TableCard
              title={<Typography variant="h1">{t('ModalScore.ModalTitle')}</Typography>}
              sx={{ width: '100%' }}
            >
              <Box sx={{ overflowX: 'auto', display: 'block', width: '100%' }}>
                <TableContainer sx={{ width: '100%' }}>
                  <Table
                    aria-label="Calificacion"
                    sx={{
                      width: '100%',
                      tableLayout: 'fixed',
                      whiteSpace: {
                        xs: 'nowrap',
                        sm: 'unset',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell key={headCell.id}>
                            <Typography variant="subtitle1" fontWeight="500">
                              {headCell.label}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableCell>
                        <Typography variant="h3">{scoreData?.nroPedido || "---"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{scoreData?.empresaDestino?.nombre}</Typography>
                      </TableCell>
                      <TableCell>
                        <Rating
                          name="simple-controlled"
                          value={puntaje}
                          onChange={(event, newValue) => {
                            scoring(newValue, scoreData);
                          }}
                          readOnly={puntaje !== 0}
                        />
                      </TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TableCard>
          </Grid>
        </Box>
      </Card>
    </PageContainer>
  );
};

export default CustomersRatings;
