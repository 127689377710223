import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { getToken } from 'firebase/messaging';
import { messaging } from '../components/activityCenter/NotificacionPush';
import { login as loginApi } from '../api/auth';
import { viewCompany } from '../api/company';
import { authReducer } from '../reducers/authReducer';
import { updateTokenApp, viewUser } from '../api/users';
import { getPermissionsMap } from '../api/permissions';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  authenticatedData: null,
  companyData: null,
  allPermissions: null,
  subscriptions: [],
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const getAllPermissions = async () => {
  try {
    const { data } = await getPermissionsMap();
    return data;
  } catch (error) {
    console.log('Error fetching permissions:', error);
    return null;
  }
};

const activarMensajes = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: 'BAYkIaXqM6Gky2G_XexXLh4kI4YK9Vcea7PNBCntsMOgxO9S9Jsp_xcv_b6c6fpTQAanGz2E210kSU6BTkdxoM8',
    });

    if (token) {
      await updateTokenApp({ token });
    }
  } catch (error) {
    // enqueueSnackbar('Error al generar el token de notificación push', {
    //   variant: 'error',
    //   anchorOrigin: { vertical: 'top', horizontal: 'right' },
    //   autoHideDuration: 4000,
    // });
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  subscriptions: [],
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken') || null;
        if (accessToken) {
          setSession(accessToken);

          // Obtener usuario y compañía
          const userResponse = await viewUser(accessToken);
          if (userResponse) {
            const companyResponse = await viewCompany(accessToken);
            const allPermissions = await getAllPermissions();
            const subscriptions = companyResponse?.promocion || [];

            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                authenticatedData: userResponse,
                allPermissions,
                subscriptions,
                companyData: companyResponse,
              },
            });
          } else {
            throw new Error('Usuario no encontrado');
          }
        } else {
          throw new Error('Token de acceso no disponible');
        }
      } catch (err) {
        // console.log('Error durante la inicialización:', err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            authenticatedData: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    try {
      const loginResponse = await loginApi({ username, password });
      if (loginResponse?.data && loginResponse.data !== 'Credenciales inválidas') {
        setSession(loginResponse?.data);

        const userResponse = await viewUser(loginResponse.data);
        const companyData = await viewCompany(loginResponse.data);
        const allPermissions = await getAllPermissions();
        const subscriptions = companyData?.promocion || [];
        activarMensajes();
        dispatch({
          type: 'LOGIN',
          payload: {
            authenticatedData: userResponse,
            allPermissions,
            subscriptions,
            companyData,
          },
        });

        return loginResponse?.data;
      }

      throw new Error('Credenciales inválidas');
    } catch (error) {
      console.log('Error al iniciar sesión:', error);
      throw error;
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
