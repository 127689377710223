import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { GoogleMap} from '@react-google-maps/api';
import GoogleMapsContext from '../contexts/MapContext';

const Map = (props) => {
  const location = useLocation();
  
  const containerStyle = {
  width: '100%',
  height: '550px',
};

  const {google}= useContext(GoogleMapsContext)

  if (google==='Error loading maps') return google;
  if (google==='Loading Maps') return google;
  
  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props?.location}
        zoom={props.zoom}
        onLoad={props.onMapLoad}
      >
        {props.children}
        <></>
      </GoogleMap>
  );
};

Map.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  zoom: PropTypes.number,
  onMapLoad: PropTypes.func,
};

export default React.memo(Map);
