import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Divider,
  Box,
  Grid,
  Typography,
  useTheme,
  Chip,
  TableContainer,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import moment from 'moment/moment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PageContainer from '../../../../components/container/PageContainer';
import Breadcrumb from '../../../../layouts/control-tower/breadcrumb/Breadcrumb';
import { incidentDetail } from '../../../../api/incident';
import LabelWithIcon from '../../../../components/LabelWithIcon';
import TableCard from '../../../../components/table-card/TableCard';

const ViewDetailIncident = () => {
  const [t] = useTranslation();
  const { id: idIncidente } = useParams();
  const [incidente, setIncidente] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const chipStyle = { fontSize: '12px', letterSpacing: '1px', color: theme.palette.text.secondary };

  const BCrumb = [
    {
      to: '/torre-de-control/incidentes',
      title: t('Incidente.incidentes'),
    },
    {
      to: '/torre-de-control/incidentes',
      title: t('Incidente.tableroDeIncidentes'),
    },
    {
      title: t('Incidente.IncidenteDetalle'),
    },
  ];

  const handlePrioridad = () => {
    switch (incidente?.prioridad) {
      case '1':
        return t('Incidente.Urgente');
      case '2':
        return t('Incidente.Alta');
      case '3':
        return t('Incidente.Media');
      case '4':
        return t('Incidente.Baja');
      default:
        return null;
    }
  };

  const fetchIncidentDetail = async () => {
    setLoading(true);
    try {
      const { data } = await incidentDetail(idIncidente);
      setIncidente(data);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIncidentDetail();
  }, []);

  return (
    <PageContainer title={t('Incidente.IncidenteDetalle')}>
      <Breadcrumb items={BCrumb} />
      <Card sx={{ p: 0 }}>
        <Box sx={{ bgcolor: 'primary.main', px: 4, py: 3 }}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" mb={1}>
            <Typography variant="h2" lineHeight="24px" mr={2} color="white">
              {t('Incidente.incidente')} {incidente?.identificador}
            </Typography>
            <ReportProblemIcon style={{ color: 'white', fontSize: '36px' }} />
          </Box>
        </Box>

        <Box p={4}>
          <Grid container spacing={2} mb={4}>
            {incidente?.descripcion && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <LabelWithIcon
                  title={t('Incidente.Descripcion')}
                  subTitle={incidente?.descripcion}
                  featherIcon="message-circle"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={t('Incidente.tipoDeIncidente')}
                subTitle={incidente?.tipoIncidente?.nombre}
                featherIcon="info"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={t('Incidente.prioridad')}
                subTitle={handlePrioridad()}
                featherIcon="info"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LabelWithIcon
                title={t('Incidente.cambioEstado.Responsable')}
                subTitle={incidente?.responsable}
                featherIcon="user"
              />
            </Grid>

            <Grid item xs={12} mb={1}>
              <Divider textAlign="center">
                <Chip label={t('Incidente.cambioEstado.StatusHistory')} sx={chipStyle} />
              </Divider>
            </Grid>

            <Grid container justifyContent="center" alignItems="center">
              <TableCard
                title={`${t('Incidente.cambioEstado.StatusSingular')} ${incidente?.identificador}`}
                custompadding="0px"
              >
                <TableContainer sx={{ width: '100%', maxWidth: 'none', overflowX: 'auto' }}>
                  {loading ? (
                    <LinearProgress />
                  ) : (
                    <Table stickyHeader sx={{ minWidth: '1200px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '20%' }}>
                            <Typography variant="h3">{t('Incidente.Estado')}</Typography>
                          </TableCell>
                          <TableCell sx={{ width: '20%' }}>
                            <Typography variant="h3">{t('Calendar.date')}</Typography>
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>
                            <Typography variant="h3">{t('TableHeader.User')}</Typography>
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>
                            <Typography variant="h3">{t('OM.comment')}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {incidente?.historialEstado.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography variant="h4" fontWeight="600">
                                {t('Fleet.Home.Assets-travels.Without-results')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {incidente?.historialEstado.length > 0 &&
                          incidente?.historialEstado?.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                              <TableCell>
                                <Typography variant="h4">
                                  {t(`Incidente.cambioEstado.${row?.estado}`)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {moment(row?.fecha).format('DD/MM/YYYY HH:mm')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {row?.usuario.nombre} {row?.usuario?.apellido}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{row?.comentario || '---'}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </TableCard>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </PageContainer>
  );
};

export default ViewDetailIncident;
