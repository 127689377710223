import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AuthGuard = ({
  children,
  permissions = { individual: null, collective: null },
  module = null,
}) => {
  const auth = useAuth();
  const modulos = useSelector((state) => state.CustomizerReducer.modulosActivos);
  const userModules = auth?.authenticatedData?.modulosActivos;
  const modulosSeguro = !modulos || modulos.length === 0 ? userModules : modulos;

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth?.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to="/auth/login" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (auth?.authenticatedData?.rol?.id === '5' && location.pathname !== '/configuracion/usuario') {
    return <Navigate to="/configuracion/usuario" />;
  }

  if (
    auth?.authenticatedData?.rol?.id === '1' &&
    location.pathname !== '/backoffice/empresas' &&
    location.pathname !== '/backoffice/usuarios' &&
    location.pathname !== '/backoffice/parametros-acceso/roles'
  ) {
    return <Navigate to="/backoffice/empresas" />;
  }

  if (
    auth?.authenticatedData?.rol?.id !== '1' &&
    (location.pathname === '/backoffice/empresas' ||
      location.pathname === '/backoffice/usuarios' ||
      location.pathname === '/backoffice')
  ) {
    return <Navigate to="/inicio" />;
  }

  if (module) {
    if (!modulosSeguro || !modulosSeguro.includes(module)) {
      return <Navigate to="/auth/login" />;
    }
  }

  if (auth?.authenticatedData?.rol) {
    const userPermissions = auth?.authenticatedData?.rol?.idPermisos;

    if (
      !userPermissions ||
      !(
        (!permissions.individual && !permissions.collective) ||
        permissions.individual?.some((i) => userPermissions.includes(i)) ||
        permissions.collective?.some((c) => c.every((d) => userPermissions.includes(d)))
      )
    ) {
      return <Navigate to="/auth/login" />;
    }
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.shape({
    individual: PropTypes.arrayOf(PropTypes.string),
    collective: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  module: PropTypes.string,
};

export default AuthGuard;