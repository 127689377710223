import axios from 'axios';
import { CALIFICACION_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const createScore = async (newScore) => {
  return axios.post(`${CALIFICACION_PATH}/guardar`, newScore);
};

export const scoreListTravel = () => {
  return axios.get(`${CALIFICACION_PATH}/listar-recibidas-transportista`)
}

export const scoreList = async (transportista) => {
  return axios.get(`${CALIFICACION_PATH}/listado/recibidas?transportista=${transportista}`);
};

export const scoreListGiverCarrier = async () => {
  return axios.get(`${CALIFICACION_PATH}/buscar-calificacion-dador-transportista`);
};

export const scoreListGiverClient = async () => {
  return axios.get(`${CALIFICACION_PATH}/buscar-calificacion-dador-cliente`);
};

export const getLoadScoreDetails = async (id) => {
  return axios.get(`${CALIFICACION_PATH}/carga/${id}`);
};

export const getTravelScoreDetails = async (id) => {
  return axios.get(`${CALIFICACION_PATH}/viaje/${id}`);
};

export const getCustomersOrders = async (idCliente, idPedido) => {
  return axios.get(
    `${CALIFICACION_PATH}/buscar-cliente-pedido?idCliente=${idCliente}&idPedido=${idPedido}`,
  );
};

export const createRatingCustomers = async (newScore) => {
  return axios.post(`${CALIFICACION_PATH}/guardar-calificacion-cliente`, newScore);
};
