import React from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const TermsAndConditionsModal = ({ open, onClose }) => {
  const theme = useTheme();
  const [t] = useTranslation();

  const style = {
    position: 'absolute',
    width: 600,
    minHeight: 600,
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.default,
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pt: 3,
    px: 4,
    pb: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '500ms',
    overflow: 'hidden',
  };

  const contentStyle = {
    maxHeight: '80vh',
    overflowY: 'auto',
    mt: 1,
    width: '80%',
    heigth: '80%',
  };


  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style} display="center">
          <Typography id="modal-modal-title" variant="h2" component="h2">
            {t("TERMS AND CONDITIONS OF USE - FOLLOW®")}
          </Typography>
          <Box style={contentStyle}>
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              Los presentes términos y condiciones (en adelante, el “Acuerdo” o “Términos”
              indistintamente) constituyen un contrato entre usted (en adelante, el “Usuario”) y
              FOLLOW, (en adelante, “FOLLOW” y, junto con el Usuario, la/s “Parte/s”), respecto del
              uso de la Plataforma y de la Aplicación Móvil de industria del transporte y logística
              (en adelante, el “Servicio Follow”). Estos Términos regirán los derechos y
              obligaciones de las Partes respecto de los servicios ofrecidos por la Plataforma, y
              serán complementarios para aquellos términos y condiciones especiales que sean
              publicados para determinados servicios, a excepción de que resulten expresamente y
              únicamente aplicables otros términos y condiciones especiales. EL USUARIO SE
              COMPROMETE A LEER, COMPRENDER Y ACEPTAR TODAS LAS CONDICIONES ESTABLECIDAS EN ESTE
              ACUERDO, AL MOMENTO DE SOLICITUD DE SU REGISTRO COMO USUARIO DE LA Plataforma. Por
              tanto, los presentes Términos se considerarán aceptados por el Usuario siempre y
              cuando éste último los haya aceptado expresamente mediante la suscripción de los
              mismos en el proceso de Apertura de Cuenta. Consecuentemente estos Términos revisten
              el carácter de obligatorios y vinculantes para las Partes. EN CASO DE NO ENCONTRARSE
              DE ACUERDO CON LOS TÉRMINOS Y CONDICIONES, POR FAVOR, ABSTÉNGASE DE UTILIZAR LOS
              SERVICIOS. Servicios: FOLLOW facilita a los Usuarios el acceso a su Servicio Follow
              que se brinda a través de su Plataforma o a través de la Aplicación Móvil. Al ingresar
              al Servicio Follow podrán acceder a distintos módulos relacionados a la industria del
              transporte y su logística (“Módulos”). El uso del Servicio Follow y los Módulos
              disponibles están sujetos a las condiciones previstas en estos Términos y a los
              Términos y Condiciones que eventualmente se publiquen. Apertura de Cuenta: Requisitos
              técnicos: Para acceder a los servicios provistos por FOLLOW, el Usuario necesitará un
              dispositivo que cumpla con los requisitos de compatibilidad y acceso a una conexión de
              Internet estable. Alta de Usuario - Apertura de Cuenta: Para proceder con la apertura
              de la cuenta en FOLLOW (“Cuenta”), el Usuario deberá haber completado el registro
              satisfactoriamente mediante el envío de la información requerida por FOLLOW y la
              creación de una contraseña (“Clave Privada”). Creación de Cuentas asociadas: Asimismo,
              el Usuario podrá habilitar distintas cuentas asociadas a la Cuenta Empresa,
              denunciando para tal fin un correo electrónico a los efectos de que FOLLOW envíe una
              notificación a la persona que resulte beneficiaria. Tras la recepción del mail, el
              titular de la cuenta asociada deberá proceder con el proceso de alta, instancia que
              requerirá la creación de una Clave Privada. El Usuario será responsable de todo acto
              que realice el tercero beneficiario de la Cuenta Asociada y podrá administrar o
              limitar el acceso a las funciones por parte del tercero beneficiario. Capacidad: El
              Usuario (y/o persona beneficiaria de la Cuenta Asociada) deberá ser mayor de edad y
              capaz de ejercicio según las leyes de su país. Finalización de proceso de Alta de
              Cuenta/Cuenta Asociada : Enviada la documentación solicitada para el proceso de
              registro de la Cuenta (y eventualmente de la Cuenta Asociada), FOLLOW procederá a
              verificarla y en caso de ser exitoso, enviará por correo un código de acceso que
              deberá ser validado en la Plataforma. Una vez validado el código, el Usuario (y/o
              titular de la Cuenta Asociada) finalizará exitosamente el proceso de apertura de su
              Cuenta. Acceso a la Cuenta/Cuenta Asociada : El Usuario (y/o titular de la Cuenta
              Asociada), podrá acceder a su Cuenta/Cuenta Asociada mediante el ingreso de su
              dirección de correo electrónico y la Clave Privada. Las Claves Privadas son personales
              e intransferibles, obligándose los Usuarios (y/o los titulares de las Cuentas
              Asociadas) a mantenerlas en confidencialidad. Asimismo, cada Usuario se compromete a
              notificar a FOLLOW en forma inmediata y por medio idóneo y fehaciente, de cualquier
              uso no autorizado de su Cuenta y/o Cuenta Asociada autorizado, así como del ingreso o
              de intentos de ingreso por terceros no autorizados al mismo. Acceso al Servicio: La
              disponibilidad del Servicio como así también los Módulos habilitados, variarán según
              el país y es posible que no todos los Servicios o Módulos estén disponibles en un país
              determinado. Asimismo, ciertos Módulos podrán ser provistos por terceros, ajenos a
              FOLLOW (“Módulos Asociados”). En ese caso, FOLLOW lo indicará expresamente, aclarando
              que no se responsabilizará ni responderá por ningún contenido y/o servicios que se
              hubiere ofrecido en el Módulo Asociado, siendo FOLLOW un mero intermediario que provee
              la tecnología necesaria para que el Usuario pueda contratar el Módulo Asociado.
              Aceptación como Usuario: En caso de ser aceptado, reunirás la condición de Usuario
              para los presentes Términos. De manera concomitante al acceso a un Módulo o contenido
              pago, se deberá definir la forma de pago, conforme la política de precios que se
              encuentre vigente, información que se encontrará actualizada en el Servicio Follow y/o
              que fuera oportunamente comunicada al momento de solicitar el Módulo o Módulo
              Asociado. Condiciones de Pago: Al aceptar estos Términos comprendés que las
              condiciones de pago quedarán sujetas a discreción exclusiva de FOLLOW, o de los
              terceros ajenos a FOLLOW (en el caso de los Módulos Asociados), pudiendo variar de
              tiempo en tiempo. El Usuario acepta pagar a FOLLOW una tarifa (la &quot;Tarifa&quot;)
              cuando resulte aplicable, según las condiciones que hubieren sido comunicadas al
              momento de solicitar cada Módulo/Módulo Asociado. Las Tarifas mencionadas
              precedentemente no incluyen los costos que aplican las empresas de telefonía celular
              por los servicios de transmisión de datos. Los pagos se realizan en la Plataforma o en
              la Aplicación Móvil de FOLLOW mediante el sistema GATEWAY o Pasarela de Pagos. Las
              pasarelas de pago son plataformas que permiten una transacción monetaria entre dos
              personas por Internet y garantizan la seguridad de las transacciones. Al elegir
              realizar un pago el Usuario será redireccionado a las pasarelas de pago propuestas por
              FOLLOW. Tu condición de Usuario del Módulo (o Módulo Asociado) será confirmada una vez
              que FOLLOW preste su conformidad con el proceso de pago que hayas realizado. Forma de
              pago: Dependiendo del país de donde descargues Servicios provistos por FOLLOW (y
              eventualmente los Módulos o Módulos Asociados), nuestras Condiciones de Pago estarán
              expresadas en una u otra moneda y modalidad de pago. Al aceptar estos Términos te
              obligas a abonar en tiempo y forma, conforme las condiciones de pago y las tarifas
              vigentes, exigibles por parte de FOLLOW. Facturación: En caso que residas en Argentina
              y necesites factura A, deberás solicitarlo al momento de completar el proceso de
              acceso al Módulo. Suscripciones: el Usuario podrá optar entre la Suscripción mensual o
              anual al Servicio Follow. Si el usuario elige la suscripción anual pagará cada mes un
              monto fijo por el plazo de 12 meses, durante el término del contrato no podrá
              rescindir el servicio y no sufrirá cambios en el precio. En caso de que el usuario
              elija la suscripción mensual el monto de la misma estará sujeto a posibles
              modificaciones las que serán notificadas por correo con un plazo de anticipación de
              diez días. El usuario podrá rescindir el servicio en cualquier momento. Reembolsos: En
              caso de que el Usuario no se encuentre conforme con el contenido del Servicio Follow o
              el mismo presentara defectos y esto fuese denunciado a FOLLOW en el plazo de diez días
              desde que se realizó el pago, FOLLOW podrá disponer el reembolso del dinero.
              Actualizaciones. Es posible que al utilizar la Aplicación Móvil, FOLLOW , sus
              Servicios, Módulos (y/o Módulos Asociados) o contenido requieran actualizaciones, como
              correcciones de errores, mejoras de funciones, complementos faltantes y versiones
              nuevas (en conjunto, &quot;Actualizaciones&quot;). Dichas Actualizaciones podrán ser
              necesarias para que el Usuario pueda usar FOLLOW o bien acceder, descargar o usar la
              Aplicación y/o los Módulos. Al aceptar estos Términos y usar FOLLOW, acepta recibir
              dichas Actualizaciones automáticamente. Asimismo, también podrá administrar las
              Actualizaciones mediante “Configuración” en FOLLOW. Sin embargo, si se determina que
              la Actualización solucionará una vulnerabilidad de seguridad crítica relacionada con
              la Aplicación o los Módulos, dicha Actualización podrá completarse independientemente
              de la configuración de la Actualización en FOLLOW o su dispositivo. Datos Personales.
              En las Políticas de Privacidad de FOLLOW, se explica cómo se administran los datos
              personales de los Usuarios y cómo se protege su privacidad cuando usa FOLLOW. Es
              posible que FOLLOW deba compartir su información personal (como su nombre y dirección
              de correo electrónico) con los proveedores a fin de ofrecerle los Módulos Asociados o
              procesar sus transacciones. Los proveedores aceptan usar esta información según sus
              políticas de privacidad. Acceso no autorizado a las cuentas. El Usuario deberá
              proteger la información de su Cuenta (como así también las Cuentas Asociadas
              habilitadas) y su Clave Privada, procurando no compartirla con ninguna otra persona.
              Cuentas inhabilitadas. Si FOLLOW inhabilita el acceso a una Cuenta (junto con las
              Cuentas Asociadas habilitadas) por el incumplimiento a los presentes Términos, es
              posible que se le impida acceder a los Módulos, a la información de su Cuenta/Cuenta
              Asociada o a los archivos, o a otro contenido almacenado en su Cuenta, sin que ello
              implique derecho a reclamar ningún daño por parte del Usuario. Modificaciones de estas
              Condiciones. Si se modifican los presentes Términos, recibirá un aviso vía correo
              electrónico y/o por notificación “push” en la Aplicación y/o publicando una versión
              actualizada de dichos Términos en la Plataforma
              www.followlsn.com/terminos-y-condiciones con expresión de la fecha de la última
              modificación. Dentro de los 5 (cinco) días corridos siguientes a la notificación de
              las modificaciones introducidas, el Usuario podrá comunicar por correo electrónico si
              no acepta las mismas; en ese caso quedará disuelto el vínculo contractual. Vencido
              dicho plazo sin haber el Usuario expresado lo contrario, se considerará que acepta los
              nuevos Términos y el contrato continuará vinculando a ambas Partes. Derechos y
              restricciones Licencia para usar la Plataforma. El Usuario tendrá el derecho no
              exclusivo (únicamente según lo permitido de forma expresa en estos Términos) de
              acceder, ver y usar la Plataforma, o según se autorice como parte del Servicio para
              uso personal. Estarán reservados todos los derechos, intereses y títulos sobre FOLLOW
              y el Servicio Follow que no se le concedan expresamente en estos Términos. Infracción
              de las condiciones. Si infringe cualquiera de las condiciones de los presentes
              Términos, FOLLOW lo intimará a cesar con la conducta infractora. Transcurridos 10 días
              sin que la conducta cese se rescindirán los derechos que se le hubieren concedido en
              virtud de esta licencia de forma inmediata y FOLLOW podrá impedir su acceso a la
              Plataforma, a la Aplicación, a Módulos habilitados y/o a su Cuenta y Cuentas Asociadas
              habilitadas sin concederle ningún reembolso. Propiedad Intelectual e Industrial: El
              uso de marcas, logos y demás derechos de propiedad intelectual de FOLLOW tales como
              texto, información, gráficos, imágenes, logos, marcas, programas de computación, bases
              de datos, diseños, APIs, arquitectura funcional y cualquier otro material (en
              adelante, &quot;el Contenido&quot;), están protegidos por las leyes vigentes
              incluyendo, pero sin limitación, las leyes sobre derechos de autor, patentes, marcas,
              modelos de utilidad, diseños industriales y nombres de dominio, se encuentren o no
              registrados, en el país o en el exterior. El Usuario se obliga a utilizar el Contenido
              de conformidad con lo estipulado en el presente y conforme las instrucciones que
              FOLLOW imparta. Por tanto, el Usuario no copiará ni adaptará el código de programación
              desarrollado por, o por cuenta de, FOLLOW para generar y operar la WebApp o los
              Servicios, en infracción a la normativa aplicable de su país. Asimismo, el Usuario se
              responsabiliza por todo uso que realicen las terceras personas que se vieran
              beneficiadas con la apertura de un Cuenta Asociada , manteniendo indemne a FOLLOW de
              cualquier hecho en violación de los presentes Términos. Restricciones: El Usuario no
              podrá hacer lo siguiente: mostrar el Contenido (ya sea de forma parcial o total) en
              ninguna representación ni exposición pública aunque sea de forma gratuita, excepto (a)
              en aquellos casos en los que dicho uso no constituya un incumplimiento de los derechos
              de autor ni de cualquier otro derecho aplicable, o (b) que así se especifique y solo
              de la forma que se permita. vender, alquilar, arrendar, redistribuir, emitir,
              transmitir, comunicar, modificar, sublicenciar, transferir ni ceder ningún Contenido a
              terceros, incluso con respecto a cualquier descarga del Contenido que se pueda obtener
              mediante FOLLOW, salvo en la forma expresamente indicada y permitida. usar FOLLOW ni
              cualquier Contenido junto con programas de copia o captura de transmisiones (o
              software similar) para grabar Contenido que se presente en formato de transmisión o
              crear copias de este. usar el Contenido como parte de un servicio que permita
              compartir contenido, prestarlo o utilizarlo junto con otras personas, o destinarlo a
              los fines de alguna otra institución, salvo en la forma expresamente indicada y
              permitida. ayudar, autorizar ni incentivar a otras personas a evadir, inhabilitar o
              anular funciones de seguridad o componentes que protejan, ofusquen o restrinjan de
              algún otro modo el acceso a cualquier Contenido o a FOLLOW. quitar marcas de agua,
              etiquetas ni otros avisos legales o sobre propiedad incluidos en cualquier Contenido,
              ni modificar el Contenido obtenido mediante FOLLOW, lo que incluye implementar cambios
              destinados a camuflar o modificar indicaciones referidas a la propiedad o al origen
              del Contenido. Disposiciones de terceros. A pesar de cualquier disposición contraria a
              estos Términos, los terceros que concedan una licencia de su contenido a FOLLOW a los
              efectos de proveer Módulos Asociados, se considerarán terceros beneficiarios en virtud
              de estos Términos únicamente con respecto a las disposiciones específicas que
              conciernen directamente al contenido (&quot;Disposiciones de terceros&quot;), y
              solamente con el propósito de permitir que los terceros ejerzan sus derechos sobre ese
              contenido. No obstante, en caso que el Módulo Asociado específicamente establezca que
              la relación contractual es entre el tercero y el Usuario, FOLLOW se considerará un
              tercero ajeno a dicha relación. Contenido defectuoso. Una vez que el Contenido se
              encuentre disponible en su Cuenta (y/o Cuentas Asociadas habilitadas), deberá probarlo
              lo antes posible para asegurarse de que funcione y se reproduzca según lo previsto. Si
              encuentra algún error o defecto, comuníquese con el proveedor (en caso de ser un
              Módulo Asociado) o con nosotros lo antes posible a través del sistema de asistencia
              previsto en la Plataforma. Eliminación o no disponibilidad del Contenido. De acuerdo
              con lo establecido en estos Términos, el Usuario podrá acceder al Contenido que compre
              o instale mediante FOLLOW mientras FOLLOW disponga del derecho a facilitarle ese
              Contenido. En algunos casos (por ejemplo, si FOLLOW pierde los derechos
              correspondientes a determinados Módulos Asociados, discontinúa un servicio o
              determinado Contenido, detecta problemas de seguridad críticos o toma conocimiento de
              que se incumplieron las condiciones aplicables o alguna disposición legal), FOLLOW
              podrá quitar el Contenido que haya comprado de su dispositivo o dejar de
              proporcionarle acceso a él. Condiciones de asistencia técnica FOLLOW proporcionará
              asistencia técnica al Cliente por correo electrónico en días laborables durante el
              horario de 9:00 a 17:00 horas GMT-3, con exclusión de los días festivos nacionales
              (&quot;Horario de asistencia&quot;). El cliente puede iniciar un ticket de ayuda
              durante el horario de asistencia enviando un correo electrónico a info@followlsn.com .
              FOLLOW hará esfuerzos comercialmente razonables para responder a todos los tickets del
              servicio de asistencia en el plazo de un (1) día laborable. Ley Aplicable.
              Competencia: Estos Términos se rigen por las leyes de la República Argentina. Toda
              acción derivada de estos Términos deberá someterse exclusivamente a la jurisdicción de
              los tribunales ordinarios con competencia Civil y Comercial, de la Primera
              Circunscripción Judicial de la Provincia de Mendoza, República Argentina, renunciando
              las partes a cualquier otro fuero o jurisdicción que pudiere corresponder.
              Misceláneas: (i) Nulidad. En caso de declararse la nulidad de alguna de las cláusulas
              de este Acuerdo, tal nulidad no afectará a la validez de las restantes, las cuales
              mantendrán su plena vigencia y efecto. (i) Domicilio. A todos los efectos emergentes
              de estos Términos, FOLLOW constituye domicilio en Belgrano 1117, Godoy Cruz, Mendoza,
              Argentina y el Usuario en el domicilio que declare al momento de realizar la apertura
              de la Cuenta. Contrato de Nivel de Servicio FOLLOW hará los esfuerzos comercialmente
              razonables para hacer que el Servicio FOLLOW esté disponible con un porcentaje de al
              menos el 99.8%, durante cualquier ciclo de facturación mensual. En el caso de que
              FOLLOW no cumpla con el compromiso de servicio, el usuario tendrá derecho a recibir un
              Crédito por Servicio, tal como se describe a continuación. Los Créditos por Servicio
              se calculan como un porcentaje de los cargos que pagó por FOLLOW cuando no cumplió con
              el compromiso del Porcentaje de Disponibilidad mensual en un ciclo de facturación de
              acuerdo con la siguiente tabla:
            </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Porcentaje de Disponibilidad mensual</TableCell>
                    <TableCell>Porcentaje de Crédito por Servicio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Menos del 99.9% pero mayor o igual al 99.0%</TableCell>
                    <TableCell>10%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Menos del 99.0% pero mayor o igual al 95.0%</TableCell>
                    <TableCell>25%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Menos del 95.0%</TableCell>
                    <TableCell>100%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              El Porcentaje de Disponibilidad Mensual es calculado usando la siguiente fórmula: D =
              (M - I) / M D significa el porcentaje de disponibilidad en un mes de facturación M
              significa la cantidad de minutos en un mes de facturación I significa la Inactividad
              en minutos durante un mes de facturación. Aplicaremos los Créditos por Servicio solo
              en futuros pagos de FOLLOW que le corresponda pagar al usuario. FOLLOW podrá emitir
              los Créditos por Servicio a la tarjeta de crédito que utilizó para pagar el ciclo de
              facturación en el que se produjo la inactividad. Los Créditos por Servicio no le darán
              derecho a reembolso u otro pago de FOLLOW. Los Créditos por Servicio no se pueden
              transferir o aplicar a ninguna otra cuenta. Para recibir Créditos por Servicio, deberá
              presentar un reclamo dentro de los diez días desde que se produjo la inactividad
              abriendo un caso en el Centro de asistencia técnica de FOLLOW. En dicho reclamo deberá
              indicar fecha y horario del incidente y en qué área del Servicio FOLLOW se produjo la
              falla. Es necesario que acompañe documentación respaldatoria de la interrupción que
              reclama. Si confirmamos el Porcentaje de Tiempo de Actividad Mensual de dicha
              solicitud de crédito y resulta inferior al Compromiso de Servicio, le emitiremos los
              Créditos de Servicio dentro de un ciclo de facturación posterior al mes en que se
              realizó la solicitud de crédito. El Compromiso de Servicio no se aplica a ninguna
              indisponibilidad, suspensión o terminación de FOLLOW, o cualquier otro problema de
              rendimiento de FOLLOW: (i) causado por factores fuera de nuestro control razonable,
              incluido cualquier evento de fuerza mayor o acceso a Internet; (ii) aquello que
              resulte de cualquier acción o inacción de su parte o de un tercero; (iii) aquello que
              resulte del incumplimiento de los presentes Términos; (iv) aquello que resulte de su
              equipo, software u otra tecnología y/o equipos, software u otra tecnología de
              terceros; o (v) que surja de la cancelación de su derecho a utilizar FOLLOW.
            </Typography>
          </Box>
          <Button sx={{ m: 1 }} variant="outlined" onClick={onClose}>
            {t("Button.Close")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default TermsAndConditionsModal;
