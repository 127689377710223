import React from 'react';
import { Grid, Box, Typography, Avatar, Paper, useTheme, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import COW from '../assets/images/logos/cow.png';
import WHEAT from '../assets/images/logos/wheat.png';
import GLASS from '../assets/images/logos/glass.png';
import LIQUID from '../assets/images/logos/liquid.png';
import ICE from '../assets/images/logos/ice.png';
import DANGER from '../assets/images/logos/danger.png';
import GENERAL from '../assets/images/logos/general.png';

import LabelWithIcon from './LabelWithIcon';
import { getTranslation } from '../utils/translation';

const LoadCard = ({ item, handleDetails }) => {
  const theme = useTheme();
  const [t, i18] = useTranslation();

  function getImageAndColor(loadType) {
    const type = loadType?.toUpperCase();
    switch (type) {
      case 'ANIMALES VIVOS':
        return { img: COW, color: 'Chocolate' };
      case 'CARGA A GRANEL':
        return { img: WHEAT, color: 'Wheat' };
      case 'CARGA FRÁGIL':
        return { img: GLASS, color: 'Indigo' };
      case 'CARGA LÍQUIDA':
        return { img: LIQUID, color: 'CornflowerBlue' };
      case 'Carga peligrosa - Explosivos'.toUpperCase():
      case 'Carga peligrosa - Gases'.toUpperCase():
      case 'Carga peligrosa - Inflamable'.toUpperCase():
      case 'Carga peligrosa - Materias tóxicas'.toUpperCase():
        return { img: DANGER, color: 'Crimson' };
      case 'CARGA PERECEDERA':
      case 'CARGA REFRIGERADA':
        return { img: ICE, color: theme.palette.info.light };
      default:
        return { img: GENERAL, color: theme.palette.primary.main };
    }
  }

  const { img, color } = getImageAndColor(item?.tipoCarga?.nombre);

  const itemPeso = item?.items?.map((element) => {
    return element?.peso;
  });
  
  const itemVolumen = item?.items?.map((element) => {
    return element?.volumen;
  });

  const itemPallet = item?.items?.map((element) => {
    const palletKey = Object.keys(element.pallet).length > 0 && Object.keys(element.pallet)[0];
    if (element?.ispallet === false) {
      return '';
    }
    return (
      <Typography>{`${t('TableHeader.PalletAmount')}: ${element?.pallet[palletKey]}`}</Typography>
    );
  });

  return (
    <Paper elevation={10} sx={{ width: 1, borderRadius: 2 }}>
      <Box
        sx={{
          backgroundColor: color,
          width: 1,
          aspectRatio: '5 / 1',
          borderTopRightRadius: 16,
          borderTopLeftRadius: 16,
        }}
      />
      <Grid container spacing={0}>
        <Grid item xs={4} />
        <Grid item xs={4} mt={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: '-90px',
            }}
          >
            <Box>
              <Box
                sx={{
                  backgroundImage: 'linear-gradient(#FFF,#FF8300)',
                  padding: '4px',
                  borderRadius: '50%',
                  border: `${
                    theme.palette.mode === 'dark' ? '1px solid #FF8300' : '1px solid #fff'
                  }`,
                  width: '80px',
                  height: '80px',
                  overflow: 'hidden',
                  margin: '0 auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  alt="userimg"
                  sx={{
                    backgroundColor: color,
                    borderRadius: '50%',
                    width: '70px',
                    height: '70px',
                    border: `${
                      theme.palette.mode === 'dark' ? '4px solid #FF8300' : '4px solid #fff'
                    }`,
                  }}
                  src={img}
                >
                  {`${item?.nroPedido?.slice(0, 2).toUpperCase()}`}               
                </Avatar>
              </Box>
              <Box
                sx={{
                  mt: '5px',
                  display: 'block',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {item.nroPedido}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '15px',
                  display: 'block',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {t("Types.CARGA")}#{item.nroCarga}
                </Typography>
              </Box>
              
              
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Grid container spacing={2} p={2} >
        <Grid item xs={12} >
          <Typography
            variant="h4"
            color={theme.palette.text.disabled}
            sx={{
              textAlign: 'center',
            }}
          >
            {getTranslation(i18, item.tipoCarga)}
          </Typography>
        </Grid>
        {item.cliente && (
          <Grid item xs={12}>
            <LabelWithIcon
              title={t('Order.Client')}
              subTitle={item.cliente.nombreCliente || item.cliente.razonSocial || item.cliente}
              featherIcon="user"
            />
          </Grid>
        )}
        {item.descripcion && item.descripcion.length > 0 && (
          <Grid item xs={12}>
            <LabelWithIcon
              title={t('Order.LoadDescription')}
              subTitle={item.descripcion}
              featherIcon="message-circle"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LabelWithIcon
            title={t('OrderBuild.Details.OccupiedCapacity')}
            featherIcon="box"
            sxChildrenBox={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {itemPeso && (
              <Typography>{`${t('Fleet.Home.New-So.Weight')}: ${
                itemPeso[0] ? `${itemPeso[0]} kg` : t('Fleet.Home.New-So.SinInformar')
              }`}</Typography>
            )}
            {itemVolumen && (
              <Typography>
                {itemVolumen[0] > 0 &&
                  `${t('Fleet.Home.New-So.Volume')}: ${
                    itemVolumen[0] ? `${itemVolumen[0]} m³` : t('Fleet.Home.New-So.SinInformar')
                  }`}
              </Typography>
            )}
            {itemPallet && <Typography>{itemPallet[0]}</Typography>}
          </LabelWithIcon>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleDetails} size="small" variant="text" sx={{ float: 'right' }}>
            <Typography variant="h4" color={color}>
              {t('Button.See-Detail')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoadCard;
