import axios from 'axios';
import { EXPENSE_PATH, KPI_EXPENSE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getExpenseByTravel = async (id) => {
  return axios.get(`${EXPENSE_PATH}/listado/gastos-viaje?id=${id}`);
};

export const newExpenses = async (expense) => {
  return axios.post(`${EXPENSE_PATH}/nuevo`, expense);
}

export const editExpenses = async (id, expense) => {
  return axios.put(`${EXPENSE_PATH}/editar?id=${id}`, expense);
}

export const deleteExpenses = async (id) => {
  return axios.delete(`${EXPENSE_PATH}/eliminar?id=${id}`);
}

export const getListExpense = async ()=>{
  return axios.get(`${KPI_EXPENSE_PATH}/listado`);
}