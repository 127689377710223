import axios from 'axios';
import { INCIDENT_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getIncidentList = async () => {
  return axios.get(`${INCIDENT_PATH}/listar-por-estado`);
};

export const getIncidenteListWithQuery = async (searchParam) => {
  return axios.get(`${INCIDENT_PATH}/listar-por-estado?search=${encodeURIComponent(searchParam)}`);
};

export const getIncidentListWithFilter = async (loadType) => {
  return axios.get(`${INCIDENT_PATH}/listado/estado`, { params: { q: loadType } });
};

export const saveIncident = async (incident) => {
  return axios.post(`${INCIDENT_PATH}/guardar`, incident);
};

export const deleteIncident = async (idIncident) => {
  return axios.delete(`${INCIDENT_PATH}/eliminar?id=${idIncident} `);
};

export const detailsIncident = async (idIncident) => {
  return axios.get(`${INCIDENT_PATH}/${idIncident}`);
};

export const changeStatus = async (formData) => {
  return axios.post(`${INCIDENT_PATH}/cambiar-estado/archivo`, formData);
};

export const incidentDetail = async (id) => {
  return axios.get(`${INCIDENT_PATH}/${id}`);
}
