import axios from 'axios';
import { VIAJE_PATH, CARGO_PATH, KPI_VIAJE_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getAmountOfTravelListWithFilter = async () => {
  return axios.get(`${VIAJE_PATH}/contadorViajeActivo`);
};

export const getAmountOfTravelListWithFilterCarga = async () => {
  return axios.get(`${VIAJE_PATH}/contadorViajeActivo/carga`);
};

export const getOSList = async ({all=false}) => {
  return axios.get(`${CARGO_PATH}/listarOrdenesDeServicio?all=${all}`);
}

export const getOSListProcedures = async ({all=false}, idProcedimiento) => {
  return axios.get(`${CARGO_PATH}/listarOrdenesDeServicio?all=${all}&idProcedimiento=${idProcedimiento}`);
}

export const getOSListProcess = async ({all=false}, idProceso) => {
  return axios.get(`${CARGO_PATH}/listarOrdenesDeServicio?all=${all}&idProceso=${idProceso}`);
}

export const getOSListWithPagination = async (size, page = 0) => {
  return axios.get(`${CARGO_PATH}/listarOrdenesDeServicio?all=true&size=${size}&page=${page}`);
}

export const getOSListWithSort = async (all, size, page, sortType, direction) => {
  return axios.get(`${CARGO_PATH}/listarOrdenesDeServicio?all=${all}&size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}`);
}

export const getOsAssignedToThirdPartiesListWithSort = async (size, page, sortType, direction) => {
  return axios.get(`${CARGO_PATH}/listarOrdenesDeServicioReasignadas?all=true&size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}`);
}

export const getOTList = async ({all=false}) => {
  return axios.get(`${VIAJE_PATH}/listarOrdenesDeTrabajo?all=${all}`);
}

export const getOTListProcedures = async ({all=false}, idProcedimiento) => {
  return axios.get(`${VIAJE_PATH}/listarOrdenesDeTrabajo?all=${all}&idProcedimiento=${idProcedimiento}`);
}

export const getOTListProcess = async ({all=false}, idProceso) => {
  return axios.get(`${VIAJE_PATH}/listarOrdenesDeTrabajo?all=${all}&idProceso=${idProceso}`);
}

export const getOTListWithPagination = async (size, page = 0, searchParam = '') => {
  return axios.get(`${VIAJE_PATH}/listarOrdenesDeTrabajo?size=${size}&page=${page}&searchParam=${encodeURIComponent(searchParam)}`);
}

export const getOTListWithSort = async (size, page, sortType = '', direction = '', searchParam = '') => {
  return axios.get(`${VIAJE_PATH}/listarOrdenesDeTrabajo?size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}&searchParam=${encodeURIComponent(searchParam)}`);
}

export const getOTListResponsable = async (idUsuario) => {
  if (!idUsuario) {
    return axios.get(`${VIAJE_PATH}/listarViajesTorreControl`);
  }
  return axios.get(`${VIAJE_PATH}/listarViajesTorreControl?idUsuario=${idUsuario}`);
};

export const getOtDetails = async (idOT) => {
  return axios.get(`${VIAJE_PATH}/ver?id=${idOT}`);
}

export const getListOfTravelTracking = async () => {
  return axios.get(`${VIAJE_PATH}/listado/seguimiento`);
}

export const getListOfTravelTrackingLoad = async () => {
  return axios.get(`${VIAJE_PATH}/listado/seguimiento/carga`);
}


export const getListOfActivosDisponibles = async (tipoActivo) => {
  return axios.get(`${VIAJE_PATH}/disponibilidadPorActivo?idTipoActivo=${tipoActivo}`);
}

export const getListInactives = async (activoSeleccionado) => {
  return axios.get(`${VIAJE_PATH}/disponibilidadPorId?id=${activoSeleccionado}`);
}

export const reassignOT = async (newAssigns) => {
  return axios.post(`${VIAJE_PATH}/asignar`, newAssigns);
}

export const createOT = async (newOT) => {
  return axios.post(`${VIAJE_PATH}/guardar`, newOT);
}

export const getOTbyOrderId = async (id)=>{
  return axios.get(`${VIAJE_PATH}/ver-por-pedido?id=${id}`);
}

export const getOtByDriverId = async (id) => {
  return axios.get(`${VIAJE_PATH}/ot-por-chofer?id=${id}`)
}

export const editRoute = async (editOT) => {
  return axios.post(`${VIAJE_PATH}/editar/ruta`,editOT)
}

export const cancelTravels = async (id) => {
  return axios.put(`${VIAJE_PATH}/cancelar?id=${id}`);
};

export const getListTravels = async (term) => {
  return axios.get(`${KPI_VIAJE_PATH}/listado?searchParam=${term}`);
};

export const changeChargingstatus =  (state,idPedido,isCarga) => {
  return axios.put(`${VIAJE_PATH}/actualizar-estado-carga?estado=${state}&idPedido=${idPedido}&isCarga=${isCarga}`);
};