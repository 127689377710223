import * as Yup from 'yup';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const webRegExp =
  /^((https?|ftp|smtp):\/\/)?(www\.)?[A-Za-z0-9][A-Za-z0-9-]*\.[A-Za-z]+\.[A-Za-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

export const formProfileValidation = Yup.object().shape({
  nombre: Yup.string().min(2, 'Mínimo 2 caracteres').max(60).required('El nombre es requerido'),
  responsable: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60)
    // .matches(/^[a-zA-Z\s]+$/, 'Solo se permiten letras'),
    .matches(/^\D+$/, 'Solo se permiten letras y caracteres especiales')
    .nullable(),
  razonSocial: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(60)
    .required('La razón social es requerida'),
  mail: Yup.string().required('El email es requerido').email('Email inválido'),
  mailInstitucional: Yup.string().email('Email inválido'),
  telefono: Yup.string()
    .matches(phoneRegExp, 'El número de teléfono no es válido')
    .required('El teléfono es requerido'),
  paisSelect: Yup.object().required('El país es requerido').nullable(''),
  identificadorFiscal: Yup.string().required('El id fiscal es requerido'),
  sitioWeb: Yup.string().matches(
    webRegExp,
    `Sitio web no válido. Por favor, evita espacios al final de la URL.`,
  ),
  
  ubicacion: Yup.object().shape({
    direccion: Yup.string().required('Direccion no válida, seleccione una de las opciones'),
    codigoPostal: Yup.string().nullable(),
    latitud: Yup.number().when('direccion', {
      is: (direccion) => direccion && direccion.length > 0,
      then: Yup.number().required('La latitud es requerida cuando hay una dirección'),
    }),
    longitud: Yup.number().when('direccion', {
      is: (direccion) => direccion && direccion.length > 0,
      then: Yup.number().required('La longitud es requerida cuando hay una dirección'),
    }),
    pais: Yup.object().shape({
      id: Yup.string(),
      nombre: Yup.string(),
    }),
    provincia: Yup.string().nullable(),
  }).transform((originalValue, originalObject) => {
    // Modificar el error y asignarlo a la propiedad 'direccion'
    if (originalObject.latitud || originalObject.longitud) {
      return originalValue;
    }
    return Yup.object()
      .label('direccion')
      .test({
        message: 'Latitud y longitud son requeridas cuando hay una dirección',
        test: () => false, // Forzar el error en 'direccion'
      });
  })
  ,
  ubicaciones: Yup.array().of(
    Yup.object()
      .shape({
        direccion: Yup.string().required('Direccion no valida, seleccione una de las opciones'),
        codigoPostal: Yup.string().nullable(),
        latitud: Yup.number().when('direccion', {
          is: (direccion) => direccion && direccion.length > 0, // Validar solo si 'direccion' está presente
          then: Yup.number().required('La latitud es requerida cuando hay una dirección'),
        }),
        longitud: Yup.number().when('direccion', {
          is: (direccion) => direccion && direccion.length > 0, // Validar solo si 'direccion' está presente
          then: Yup.number().required('La longitud es requerida cuando hay una dirección'),
        }),
        pais: Yup.object().shape({
          id: Yup.string(),
          nombre: Yup.string(),
        }),
        provincia: Yup.string().nullable(),
      })
      .transform((originalValue, originalObject) => {
        // Modificar el error y asignarlo a la propiedad 'direccion'
        if (originalObject.latitud || originalObject.longitud) {
          return originalValue;
        }
        return Yup.object()
          .label('direccion')
          .test({
            message: 'Latitud y longitud son requeridas cuando hay una dirección',
            test: () => false, // Forzar el error en 'direccion'
          });
      }),

  ),
});
