import React, { useEffect, useState } from 'react';
import { Button,Grid,Pagination, Paper, TablePagination, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CargaCard } from './CargaCard';
import { useCargas } from './cargaHook';
import HeaderBarWithSort from './HeaderBarWithSort';
import DetalleDeLaPublicacion from './DetalleDeCarga/DetalleDeLaPublicacion';
import Spinner from '../../../spinner/Spinner';
import { getCheckFavorites, inviteUrl } from '../../../../api/favorite';
import { getCargasPaginado } from '../../../../api/marketplace';

const Cargas = ({ selectedFilters, url, permitirOrdenar, showMessage , handleClose, comparator, setComparator }) => {
  const [t] = useTranslation();
  const {
    // cargas,
    detalleAbierto,
    detalleCarga,
    editCarga,
    handleDetalle,
    removeCarga,
    reloadCargas,
  } = useCargas(selectedFilters, url);
const [cargas, setCargas] = useState([])
const [order, setOrder] = useState('DESC')
const [page, setPage] = useState(0)
const [sizePage, setSizePage] = useState(10)
  const [modificacionDeOrden, setModificacionDeOrden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkFav, setCheckFav] = useState(false)

  // const checkFavorites = () => {
  //   getCheckFavorites('',url)
  //   .then(res => {
  //     if(res.data.id) {
  //       setCheckFav(true)
  //     }
  //   });

  // }

  const add = async () => {
    setLoading(true);
    try {
      await inviteUrl(url);
      showMessage(t('Loads.Favorites.OnSuccessInvite'), 'success');
      handleClose(true);
    } catch (error) {
      console.log(error?.response?.data);
      showMessage(
        t(`Select.${error?.response?.data?.error}`)
         ||
        error?.response?.data?.message ||
        t('Errors.Server'), 'error'
      );
    }
    finally {
      setLoading(false);
    }
  };

  const getCargas = async () => {
    const listadoCargas = await getCargasPaginado(page, sizePage,'nroCarga', selectedFilters, order)
    setCargas(listadoCargas.data)
  }

  const handleChangePage = (event, value) => {
    setPage(value);
    getCargas()
  }

  const ordenar = () => {
    setOrder(order === 'ASC' ? 'DESC':'ASC')
    getCargas()
  }

  useEffect(() => {
    getCargas();
  }, [selectedFilters]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const cargaCompartida = cargas?.find((carga) => carga.id === id);
      if (cargaCompartida) handleDetalle(cargaCompartida);
    }
  }, [cargas]);

  if (loading)
    return (
      <Box
        sx={{
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    );

  return (
    <>
      <HeaderBarWithSort
        cantidadCargas={cargas?.length}
        ordenar={ordenar}
        setCargas={setCargas}
        setModificacionDeOrden={setModificacionDeOrden}
        modificacionDeOrden={modificacionDeOrden}
        permitirOrdenar={permitirOrdenar}
      />
      <Box sx={{ overflowY: 'auto' }}>
        {cargas?.content?.length > 0 ? (
          <>
            {cargas.content.map((unaPublicacion) => {
              return (
                <CargaCard
                  key={unaPublicacion.id}
                  publicacion={unaPublicacion}
                  removeCarga={removeCarga}
                  editCarga={editCarga}
                  open={detalleAbierto}
                  handleDetalle={handleDetalle}
                  comparator={comparator}
                  setComparator={setComparator}
                />
              );
            })}
            {detalleCarga && detalleAbierto && (
              <DetalleDeLaPublicacion
                open={detalleAbierto}
                handleClose={handleDetalle}
                detalle={detalleCarga}
                reloadDetails={reloadCargas}
              />
            )}
            <Pagination
              count={cargas.totalPages}
              page={page +1}
              onChange={handleChangePage}
              sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5, mt: 5 }}
              color="primary"
            />
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, width: '100%' }}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
              elevation={3}
            >
              <Typography color="#616161" variant="h3">
                {`${t('Message.No results found')}`}
              </Typography>
            </Paper>
          </Box>
        )}
        
      </Box>
      <Box  sx={{ height: '70vh', overflowY: 'auto', display: 'contents'}}>
        <Grid
                item
                xs={12}
                md={6}
                align="center"
                display="content"
                alignItems="center"
                justifyContent="center"
                mt={4}
                ml={50}
                mr={50}
              >
                {/* {!checkFav && (
                <Button
                  size="small"
                  variant="contained"
                   onClick={add}
                   sx={{
                    width:'100%',
                  }}
                >
                  <Typography variant="h3">Agregar a favoritos</Typography>
                </Button>
                )} */}
              </Grid>
      </Box>
    </>
  );
};

export default Cargas;
