import React, { useContext } from 'react';
import {
  Box,
  Grid,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography
} from '@mui/material';
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip
} from 'recharts';
import { useTranslation } from 'react-i18next';

import { expensesPerMonth } from '../utils/handleDataToTimeFrame';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

const ChartBarWithAverage = ({ title, data, xDataKey, barDataKey,
   lineDataKey, strToolstip1, strToolstip2, strToolstip3, borderBarColor, 
   barColor, lineColor, dataGastos, setDataGastosMonth}) => {
  const [t] = useTranslation();
  const {padMarByScreen, heightCardHeader, heightScreen, sizeFontCardHeader, mMainCard}= useContext(ScreenSizeContext)
  const maxWidth = 80;
  const barWidth = Math.min(maxWidth, 200 / data?.length);


  return ( data && 
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb: 1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen,height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={title}
        
      />
      <Divider sx={{ backgroundColor: 'orange', marginBottom: heightScreen>900? 3:0, mx:2 }}/>
      <CardContent >
        <Grid container justifyContent="center">
          <Grid item   xs={12} sm={12} md={12} >
              <ResponsiveContainer width="100%" height={500} >
                <ComposedChart  data={data} width="100%" height={500}>
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey={xDataKey} axisLine={false} tickSize={5}
                   /* label={{ value: t('Months') ,  position: 'insideBottomRight', offset:-5 }} */
                  tickFormatter={(str)=>{
                    const strArray = str.split('/');
                    return `${t(`m-${strArray[0]}`)} ${strArray[1]}`;   
                  }}
                  onClick={(value) => {
                   const resultado= expensesPerMonth(dataGastos,value.value )
                   setDataGastosMonth(resultado)
                  }} 
                  cursor="pointer"
                  />
                 
                  <YAxis 
                  tickCount={100}
                  axisLine={false}
                  tickLine={false}
                  tickSize={1}
                  label={{ value: t('Bills') , angle: -90, position: 'insideBottomLeft', offset:10 }}
                  tickFormatter={(str)=>{

                    if(str>=1000000000000) return Number.isInteger(str/1000000000000)? `${(str/1000000000000).toString()}B` : `${(str/1000000000000).toFixed(1)}B`

                    if(str>=1000000) return Number.isInteger(str/1000000)? `${(str/1000000).toString()}M`: `${(str/1000000).toFixed(1)}M`

                    if(str>=1000) return Number.isInteger(str/1000)? `${(str/1000).toString()}K`: `${(str/1000).toFixed(1)}K`
                    
                    return Number.isInteger(str)? str.toString(): str.toFixed(1)
                  }} 
                    />
                  <Tooltip
                    content={(props) => <CustomTooltip strToolstip1={strToolstip1} strToolstip2={strToolstip2} strToolstip3={strToolstip3} {...props} />}
                  />
                  <Legend verticalAlign='bottom' height={1} align='right'/>
                  <Bar dataKey={barDataKey} 
                  stroke={borderBarColor} fill={barColor}
                  name={t("Total-expense")}
                  barSize={barWidth}
                  onClick={(value)=> {
                    const resultado= expensesPerMonth(dataGastos,value.date )
                   setDataGastosMonth(resultado)
                  }}
                  cursor="pointer"
                  background={{ fill: '#F0F0F0', padding: 5, opacity: 0.5, cursor:"pointer" }} 
                  />
                   
                  <Line
                    type="linear"
                    dataKey={lineDataKey}
                    stroke={lineColor}
                    strokeWidth={3}
                    name={t("Average spending")}
                  />
                </ComposedChart>
              </ResponsiveContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChartBarWithAverage;


const CustomTooltip = ({ active, payload, label, strToolstip1, strToolstip2 , strToolstip3}) => {
  const [t] = useTranslation();
  const date = label?.split("/");
  if (active && payload && payload?.length) {
    return (
      <Card
        sx={{
          flexShrink: 0,
          p: 1,
          paddingBottom: 0,
        }}
      >
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={`${t(`m-${date[0]}`)} ${date[1]}`}
          subheader={`${strToolstip3}: ${Number.isInteger(payload[0].payload.cantidad)? payload[1].payload.cantidad.toString(): payload[0].payload.cantidad.toFixed(1)}`}
        />
        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ paddingLeft:1, paddingTop:0, margin: 0, marginBottom: -2 }}>
          <Box
              sx={{
                backgroundColor: 'white',
                marginTop: 1,
                borderLeft: 6,
                borderLeftColor: payload[0].color,
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                paddingLeft: 0.5,
              }}
            >
              <Typography>{`${strToolstip1}: $${Number.isInteger(payload[0].value)? payload[0].value.toString(): payload[0].value.toFixed(1)}`}</Typography>
          </Box> 
             
          <Box
            sx={{
              backgroundColor: 'white',
              marginTop: 1,
              borderLeft: 6,
              borderLeftColor: payload[1].color,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              paddingLeft: 0.5,
            }}
          >
            <Typography>{`${strToolstip2}: $${Number.isInteger(payload[1].value)? payload[1].value.toString(): payload[1].value.toFixed(1)}`}</Typography>
          </Box>       
        </CardContent>
      </Card>
    );
  }
  return null;
}
