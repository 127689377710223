import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import TranslationButton from '../../components/Translation/TranslationButton';
import Cargas from './dashboard/cargas';

const PrivateMarketplace = () => {
  const {url} = useParams()
  const [selectedFilters, setSelectedFilters] = useState({});
  const theme = useTheme();
  const [t] = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const items = [
    {
      title: t('Icons.Marketplace'),
    },
  ];

  return (
    <PageContainer title="Marketplace" description={`Marketplace ${url}`}>
      <Breadcrumb items={items} />
      <Grid sx={{ position: 'absolute', top: '0px', right: '0px' }}>
        <TranslationButton />
      </Grid>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Cargas selectedFilters={selectedFilters} url={url} />
      </Grid>
    </PageContainer>
  );
};

export default PrivateMarketplace;
