import axios from 'axios';
import { MARKET_PLACE_PATH, CARGO_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const getCargas = async () => {
  return axios.get(`${MARKET_PLACE_PATH}/publicados`);
};

export const getCargasPaginado = async (page, pageSize, sortType, filter, direction) => {
    let query = `${MARKET_PLACE_PATH}/publicados?page=${page}&size=${pageSize}${sortType && `&sort=${sortType},${direction.toUpperCase()}`}`
    for (let i = 0; i < filter.length; i++) {
      query = `${query}&${filter[i].name}=${filter[i].value}`
      
    }
    return axios.get(query);

  //   if(idTipoCarga && idTipoRemolque && idTipoCamion && idTipoBitren
  //     && idFavorito && tipoOferta) {
  //     }
  // return axios.get(`${MARKET_PLACE_PATH}/publicados?page=${page}&size=${pageSize}
  //   &sort=${sortType1},${direction.toUpperCase()}`);
};

export const getPedido = async (id) => {
  return axios.get(`${MARKET_PLACE_PATH}/pedido`, { params: { id } });
};

export const getCargasPublicadas = async () => {
  return axios.get(`${CARGO_PATH}/listado/publicado`);
};

export const getCargasEnVista = async () => {
  return axios.get(`${MARKET_PLACE_PATH}/interacciones`);
};

export const deleteCargaMP = async (id) => {
  return axios.delete(`${MARKET_PLACE_PATH}/eliminar-interaccion?id=${id}`);
};

export const getDetalleCarga = async (id) => {
  return axios.get(`${CARGO_PATH}/detalle/${id}`);
};

export const deleteCarga = async (id) => {
  return axios.delete(`${CARGO_PATH}/eliminar?id=${id}`);
};

export const pausarCarga = async (id) => {
  return axios.put(`${CARGO_PATH}/pausar?id=${id}`);
};

export const publicarCarga = async (id) => {
  return axios.get(`${CARGO_PATH}/publicar?id=${id}`);
};

export const cancelarCarga = async (id) => {
  return axios.put(`${CARGO_PATH}/cancelar?id=${id}`);
};

export const getPedidosPublicados = async (page, pageSize, urlAmigable) => {
  if (page && pageSize) {
    return axios.get(
      `${MARKET_PLACE_PATH}/publicados/${urlAmigable}?page=${page}&size=${pageSize}`,
    );
  }
  return axios.get(`${MARKET_PLACE_PATH}/publicados/${urlAmigable}`);
};

export const getPublicOrderDetail = async (id) => {
  return axios.get(`${MARKET_PLACE_PATH}/pedido`, { params: { id } });
};
