import React, { useState, useEffect } from 'react';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es, enUS } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

// components
import CustomFormLabel from '../forms/custom-elements/CustomFormLabel';
import CustomTextField from '../forms/custom-elements/CustomTextField';
import CustomSwitch from '../forms/custom-elements/CustomSwitch';
import CustomTextFieldWithValidation from '../CustomTextFieldWithValidation';
import { ViewAttachment } from '../../api/documents';

function getFileIcon(fileIconString) {
  switch (fileIconString.split('.').pop()) {
    case 'jpeg':
    case 'jpg':
    case 'png':
      return <ImageIcon sx={{ color: (theme) => `${theme.palette.error.main} !important` }} />;
    case 'pdf':
      return (
        <PictureAsPdfIcon sx={{ color: (theme) => `${theme.palette.error.main} !important` }} />
      );
    case 'doc':
    case 'docx':
      return <TextSnippetIcon sx={{ color: (theme) => `${theme.palette.info.main} !important` }} />;
    case 'xls':
    case 'xlsx':
      return (
        <TableChartIcon sx={{ color: (theme) => `${theme.palette.success.main} !important` }} />
      );
    default:
      return (
        <InsertDriveFileIcon
          sx={{ color: (theme) => `${theme.palette.primary.main} !important` }}
        />
      );
  }
}

const validFileTypes = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const newDocumentDefault = {
  titulo: '',
  descripcion: '',
  vencimiento: '',
  archivos: [],
};

const NewDocumentModal = ({
  open,
  Transition,
  handleClose,
  handleUploadDocument,
  disabled,
  documentToEdit,
  loading,
}) => {
  const [t, i18next] = useTranslation();
  const [newDocument, setNewDocument] = useState({
    ...newDocumentDefault,
    vencimiento: new Date(),
  });
  const [hasDate, setHasDate] = useState(false);
  const [shared, setShared] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [touchedTitle, setTouchedTitle] = useState(true);

  const handleFocus = () => {
    setTouchedTitle(false);
  };

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseOutside = (event, reason) => {
    if (reason && reason === 'backdropClick' && disabled) return;
    handleClose();
    setTouchedTitle(true);
  };

  const resetForm = () => {
    setNewDocument({
      titulo: documentToEdit.titulo,
      descripcion: documentToEdit.descripcion || '',
      vencimiento: documentToEdit.vencimiento ? new Date(documentToEdit.vencimiento) : new Date(),
      archivos: documentToEdit.archivos.map((a) => a.split('/').pop()),
      id: documentToEdit.id,
    });
    setHasDate(Boolean(documentToEdit.vencimiento));
    setFilesToUpload([]);
    setFilesToRemove([]);
    setTouchedTitle(true);
  };

  const clearForm = () => {
    setNewDocument({
      ...newDocumentDefault,
      vencimiento: new Date(),
    });
    setHasDate(false);
    setFilesToUpload([]);
    setTouchedTitle(true);
  };

  useEffect(() => {
    if (documentToEdit && open) {
      resetForm();
    } else if (open) clearForm();
  }, [documentToEdit, open]);

  function handleChangeNewDocument(e) {
    const { name, value } = e.target;
    setNewDocument({
      ...newDocument,
      [name]: value,
    });
  }

  const fileExist = (file) => {
    return newDocument.archivos.some(
      (archivo) => archivo.toUpperCase() === file.name.toUpperCase(),
    );
  };

  const getFile = (file) => {
    if (documentToEdit) {
      const currentFiles = filesToUpload.find((f) => f.name === file);
      const filePath = documentToEdit.archivos.find((a) => a.includes(file));
      if (currentFiles) return currentFiles;
      window.open(ViewAttachment(filePath), '_blank');
      return false;
    }
    return filesToUpload.find((f) => f.name === file);
  };

  const validateFile = (file) => {
    return file && validFileTypes.includes(file.type);
  };

  function handleAddFile(e) {
    const [fileToUpload] = e.target.files;

    if (fileExist(fileToUpload)) {
      e.target.value = null;
      enqueueSnackbar(
        `${t('Documentation.A file already exists with the name')}: ${fileToUpload.name}`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 4000,
        },
      );
    } else if (!validateFile(fileToUpload)) {
      e.target.value = null;
      enqueueSnackbar(t('Documentation.Invalid file'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    } else {
      setFilesToUpload([...filesToUpload, fileToUpload]);
      setNewDocument({
        ...newDocument,
        archivos: [...newDocument.archivos, fileToUpload.name],
      });
    }
  }

  function removeFile(fileToRemove) {
    setNewDocument({
      ...newDocument,
      archivos: newDocument.archivos.filter((a) => a !== fileToRemove),
    });
    setFilesToUpload(filesToUpload.filter((f) => f.name !== fileToRemove));

    if (documentToEdit) {
      const filepathToRemove = documentToEdit.archivos.find((a) => a.includes(fileToRemove));
      if (filepathToRemove && !filesToRemove.some((f) => f.includes(fileToRemove)))
        setFilesToRemove([...filesToRemove, filepathToRemove]);
    }
  }

  function viewFile(filename) {
    const fileFound = getFile(filename);

    if (!fileFound) return;

    const objUrl = URL.createObjectURL(fileFound);

    if (filename.match(/^.*\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
      const image = new Image();
      image.src = objUrl;
      const w = window.open('about:blank');
      setTimeout(() => {
        w.document.write(image.outerHTML);
      }, 0);
    } else {
      const newWindow = window.open('about:blank');
      setTimeout(() => {
        newWindow.location.href = objUrl;
      }, 0);
    }
  }

  function handleConfirm() {
    handleUploadDocument(
      newDocument,
      filesToUpload,
      hasDate,
      shared,
      Boolean(documentToEdit),
      filesToRemove,
    );
  }

  const localeMapping = {
    es,
    pt: es,
    en: enUS,
  };

  const adapterLocales = localeMapping[i18next.language] || enUS;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseOutside}
      scroll="paper"
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" alignItems="center">
          <NoteAltIcon style={{ fontSize: '32px', color: theme.palette.primary.main }} />
          <Typography variant="h3" ml={1}>
            {`${
              !documentToEdit
                ? t('Documentation.Add documentation')
                : t('Documentation.Edit documentation')
            }`}
          </Typography>
        </Box>
        <IconButton
          style={{ float: 'right' }}
          onClick={() => {
            if (!disabled) {
              handleClose();
              setTouchedTitle(true);
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ backgroundColor: alpha(theme.palette.primary.dark, 0.8) }}>
        <Paper elevation={10} sx={{ px: 4, py: 2 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <CustomFormLabel
                sx={{
                  mt: 0,
                  fontSize: '18px',
                }}
              >
                <Typography fontSize="18px">
                  {t('Loads.Documents_View.Document_Title')}*:
                </Typography>
              </CustomFormLabel>
              <CustomTextFieldWithValidation
                value={newDocument.titulo}
                isValid={touchedTitle || (!touchedTitle && newDocument.titulo.length > 0)}
                name="titulo"
                placeholder={t('Documentation.Document title')}
                onChange={handleChangeNewDocument}
                onFocus={handleFocus}
              />
            </Grid>

            <Grid item sm={12}>
              <CustomFormLabel
                sx={{
                  mt: 0,
                  fontSize: '18px',
                }}
              >
                <Typography fontSize="18px">{t('Loads.Outset.Description')}:</Typography>
              </CustomFormLabel>
              <CustomTextField
                variant="outlined"
                fullWidth
                name="descripcion"
                size="small"
                value={newDocument.descripcion}
                onChange={handleChangeNewDocument}
                autoComplete="off"
                rows={3}
                multiline
                placeholder={t('Documentation.Optional document description')}
              />
            </Grid>

            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ float: 'left' }}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={hasDate}
                        onChange={() => setHasDate(!hasDate)}
                        disabled={disabled}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '18px' }}>
                        {t('Loads.Documents_View.Expires')}
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocales}>
                    <DatePicker
                      sx={{ minWidth: '300px', width: '100%' }}
                      label={t('Documentation.Expiration date')}
                      renderInput={(props) => <TextField {...props} fullWidth />}
                      inputFormat="dd/MM/yyyy"
                      value={newDocument.vencimiento}
                      minDate={new Date()}
                      disabled={!hasDate}
                      onChange={(newValue) => {
                        setNewDocument({
                          ...newDocument,
                          vencimiento: newValue,
                        });
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <CustomFormLabel
                    sx={{
                      height: 1,
                      mt: 0,
                      fontSize: '18px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography fontSize="18px">{t('Documentation.Attached files')}*:</Typography>
                  </CustomFormLabel>
                </Grid>
                <Grid
                  item
                  sm={6}
                  sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <IconButton
                    sx={{ '&:hover': { color: theme.palette.primary.main } }}
                    component="label"
                  >
                    <FeatherIcon icon="file-plus" />
                    <Typography ml={1}>{t('Documentation.Add file')}</Typography>
                    <input key={Date.now()} type="file" hidden onChange={handleAddFile} />
                  </IconButton>
                </Grid>
                <Grid item sm={12} mt={0} pt={0}>
                  {newDocument.archivos.length === 0 && (
                    <Typography fontSize="16px">
                      {t('Documentation.No attachments added')}
                    </Typography>
                  )}

                  {newDocument.archivos.map((archivo, index) => (
                    <Grid container spacing={1} key={`file-${archivo}-${index.toString()}`}>
                      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        {getFileIcon(archivo)}
                      </Grid>
                      <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{archivo}</Typography>
                      </Grid>
                      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          sx={{ '&:hover': { color: 'crimson' } }}
                          onClick={() => removeFile(archivo)}
                        >
                          <FeatherIcon icon="x" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          sx={{ '&:hover': { color: theme.palette.primary.main } }}
                          onClick={() => viewFile(archivo)}
                        >
                          <FeatherIcon icon="eye" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ float: 'left' }}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={shared}
                        onChange={() => setShared(!shared)}
                        disabled={disabled}
                      />
                    }
                    label={<Typography sx={{ fontSize: '18px' }}>{t('Button.Share')}</Typography>}
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ margin: 1, pl: 3, pr: 3 }}
          onClick={() => {
            handleClose();
            setTouchedTitle(true);
          }}
          variant="contained"
          size="small"
        >
          <Typography variant="h3">{t('Button.Cancel')}</Typography>
        </Button>
        <Button
          sx={{ margin: 1, pl: 3, pr: 3 }}
          onClick={handleConfirm}
          variant="contained"
          size="small"
          disabled={
            newDocument?.titulo === '' ||
            newDocument?.titulo === null ||
            newDocument?.titulo === undefined ||
            newDocument?.archivos?.length === 0 ||
            loading
          }
        >
          <Typography variant="h3">
            {`${!documentToEdit ? t('Button.Add') : t('Button.Save')}`}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewDocumentModal.propTypes = {
  open: PropTypes.bool,
  Transition: PropTypes.object,
  handleClose: PropTypes.func,
};

export default NewDocumentModal;
