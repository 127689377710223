import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalAction from '../DetalleDeCarga/ModalAction';

export default function ActionButton({ options, handleClose, condition }) {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState({ title: t('Button.SelectAnOption') });
  const [modalAction, setModalAction] = useState(false);

  const handleCloseModal = () => {
    setModalAction(false);
    handleClose();
  };

  const bodyCheck = () => {
    if (selectedOption.body) {
      setModalAction(true);
    } else {
      selectedOption.action?.();
    }
  };

  const handleClick = () => {
    if (modalAction) {
      selectedOption.action?.();
    } else {
      bodyCheck();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseButtonOptions = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setSelectedOption({ title: t('Button.SelectAnOption') });
  }, [t]);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ maxHeight: 50, boxShadow: 'initial' }}
        disabled={condition}
      >
        <Button size="small" onClick={handleClick}>
          <Typography fontSize={15}>{selectedOption?.title}</Typography>
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseButtonOptions}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => {
                    return (
                      <MenuItem
                        key={option.name}
                        onClick={() => {
                          setSelectedOption(option);
                          handleToggle();
                        }}
                      >
                        <Typography fontSize={15}>{option?.title}</Typography>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {options.name !== 'Contactar' && (
        <Dialog open={modalAction} onClose={handleCloseModal}>
          <ModalAction
            action={selectedOption}
            onClose={handleCloseModal}
          />
        </Dialog>
      )}
    </>
  );
}
